import { db, auth } from '../firebase/config';
import { getAuth } from 'firebase/auth';
export const submitFormHandler =  (formData) => {



  const user = auth.currentUser.uid;
  const email = auth.currentUser.email;
  console.log("User ID:", auth.currentUser);  
  
  
  // Extract form data and prepare final payload
  const finalPayload = {
    // Configuration for the fine-tuning process
    "config_dict": {
      "email": email || null,
      "finetuneType": formData.finetuneType || "Not specified",
      "creation_name": formData.creationName || "default_name",
      "name_model": formData.advancedOptions?.model || "mini-instruct",
      "max_seq_length": formData.advancedOptions?.["Max Sequence Length"] || 2048,
      "user_id":user,
      "dtype": null,
      "trainingLocation": formData.trainingLocation || "cloud", 
      "optimizeForSilicon": formData.optimizeForSilicon || "false",
      "modelSaving": formData.modelSaving || "save-hf",
      "load_in_4bit": false,
      "hf_dataset": formData.hfDatasetUrl || formData.selectedDataset || null,
      "save_name": formData.hfRepoName || "Lora_N1",
      "dir_local_save": "./local/",
      "test_size": formData.advancedOptions?.["Evaluation Split"] || 0.005,
      "instruction_col": formData.instructionCol || "instruction",
      "input_col": formData.inputCol || "input",
      "output_col": formData.outputCol || "output",
      "seed": 42,
      "generation_length": formData.advancedOptions?.["Generation Length"] || 1024,
      "HF_TOKEN": formData.hfApiKey || null ,
      "safe_serialization": null,
      "quantization_method": formData.advancedOptions?.["Quantization Method"] || "f16",
      "finish_option": 4,
      "lr_scheduler_type": formData.advancedOptions?.["Learning Rate Scheduler Type"] || "linear",
      "weight_decay": formData.advancedOptions?.["Weight Decay"] || 0.01,
      "optim": formData.advancedOptions?.Optimizer || "adamw_8bit",
      "logging_steps": formData.advancedOptions?.["Logging Steps"] || 25,
      "learning_rate": formData.advancedOptions?.["Learning Rate"] || 0.0002,
      "warmup_steps": 5,
      "num_train_epochs": formData.advancedOptions?.Epochs || 1,
      "save_steps": 25,
      "gradient_accumulation_steps": formData.advancedOptions?.gradient_accumulation_steps || 4,
      "per_device_eval_batch_size": formData.advancedOptions?.per_device_eval_batch_size || 4,
      "per_device_train_batch_size": formData.advancedOptions?.per_device_train_batch_size || 4,
      "packing": false,
      "dataset_num_proc": 2,
      "output_dir": formData.advancedOptions?.output_dir || "outputs"
    },
    // Training-specific configurations
    "training_config": {
      "r": formData.advancedOptions?.["Rank"] || 16,
      "target_modules": ["q_proj", "k_proj", "v_proj", "o_proj", "gate_proj", "up_proj", "down_proj"],
      "lora_alpha": formData.advancedOptions?.["Lora Alpha"]*formData.advancedOptions?.["Rank"] || 16,
      "lora_dropout": 0,
      "bias": "none",
      "use_gradient_checkpointing": formData.advancedOptions?.use_gradient_checkpointing || false,
      "random_state": 42,
      "use_rslora": false,
      "loftq_config": null
    },
    // Evaluation configurations
    "eval_config": {

      "evaluation_dataset_type": formData.evaluationDatasetType || "default_type",
      "evaluation_dataset": formData.Eval_HF_URL || null,
      "generateEvaluationDataset": formData.generateEvaluationDataset || false,
      "evaluation_metrics": {
        "loss": formData.evaluationMetrics?.loss || false,
        "perplexity": formData.evaluationMetrics?.perplexity || false,
        "ai_eval": formData.evaluationMetrics?.aiEval || false
      },//Possibly remove this
      "ai_eval_metrics": formData.aiEvalMetrics || {},
      "openai_api_key": formData.openAiApiKey || null,
      "generate_from_pdf": formData.evaluationMetrics?.GenePDF || false
    },
    // Modal API tokens
    "modal_token_id": formData.modalAccessKey || null,
    "modal_token_secret":  formData.modalSecret || null
  };
  

  // Send the payload
  console.log("Form submitted with data:" , JSON.stringify(finalPayload));
  try {
    fetch('http://0.0.0.0:8000/qna_finetuning/SFTIIO', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(finalPayload)
    })

    // navigate("/finetuning-dashboard");


  } catch (error) {
    console.error('Error submitting form:', error);
    return false;
  }






};
