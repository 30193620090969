import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from "firebase/functions";
import { getStorage } from 'firebase/storage'; // Add Storage if needed


 const firebaseConfig = {
    apiKey: "AIzaSyA57BxfUNfH6AvY4L1o4-y2ITxorlkWZ6o",
     authDomain: "tune-3ebf1.firebaseapp.com",
    projectId: "tune-3ebf1",
    storageBucket: "tune-3ebf1.firebasestorage.app",
    messagingSenderId: "671986740553",
    appId: "1:671986740553:web:233d71e3c9fb1f5d7ffe98",
    measurementId: "G-PVG4NWQ32D"
};
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);
export const functions = getFunctions(app); // Export functions if used in other places
export const storage = getStorage(app); // If you need to use storage
export { app };