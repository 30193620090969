import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LogoutButton from './logoutbutton';
import { Plus, Hammer, Database, User } from 'lucide-react';

const navItems = [
  { name: "Tune Runs", icon: Hammer },
  { name: "My Datasets", icon: Database, disabled: true, comingSoon: true },
  { name: "Profile", icon: User },
];

export default function Header({ 
  showNewTuneButton = false, 
  showDashboardButton = false,
  activeItem,
  onNavItemClick
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const isDashboard = location.pathname === '/finetuning-dashboard';

  return (
    <header className="bg-white shadow-sm py-4 sticky top-0 z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-14">
          <div className="flex items-center">
            <div 
              className="flex-shrink-0 flex items-center cursor-pointer" 
              onClick={() => navigate('/finetuning-dashboard')}
            >
              {/* <img 
                src="/favicon.ico" 
                alt="Tune Logo" 
                className="h-8 w-8 mr-2"
                style={{ objectFit: 'contain' }}
              /> */}
              <span className="Tune-logo text-xl font-bold">Tune</span>
            </div>
            {isDashboard && (
              <div className="hidden md:block ml-10">
                <div className="flex items-center space-x-4">
                  {navItems.map((item) => (
                    <button
                      key={item.name}
                      onClick={() => !item.disabled && onNavItemClick?.(item.name)}
                      className={`relative px-3 py-2 rounded-md text-sm font-medium transition-colors ${
                        activeItem === item.name
                          ? "bg-blue-50 text-blue-700"
                          : item.disabled
                          ? "text-gray-400 cursor-not-allowed"
                          : "text-gray-600 hover:bg-gray-50 hover:text-gray-900"
                      }`}
                      disabled={item.disabled}
                    >
                      <div className="flex items-center">
                        {React.createElement(item.icon, {
                          className: `w-4 h-4 mr-2 ${item.disabled ? 'opacity-50' : ''}`,
                        })}
                        {item.name}
                        {item.comingSoon && (
                          <span className="absolute -top-2 -right-2 px-2 py-0.5 bg-blue-100 text-blue-600 text-xs rounded-full">
                            Coming Soon
                          </span>
                        )}
                      </div>
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className="flex items-center space-x-4">
            {showNewTuneButton && (
              <>
                <button
                  onClick={() => navigate("/finetune-form")}
                  className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors"
                >
                  <Plus className="w-4 h-4 mr-2" />
                  New Fine-Tuning
                </button>
                <div className="border-l border-gray-200 h-6" />
              </>
            )}
            {showDashboardButton && (
              <button
                onClick={() => navigate("/finetuning-dashboard")}
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors"
              >
                Dashboard
              </button>
            )}
            <LogoutButton />
          </div>
        </div>
      </div>
    </header>
  );
} 