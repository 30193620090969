/**
 * DatasetsSection.js
 * A component that manages the display and upload of datasets for fine-tuning.
 * Provides functionality for viewing available datasets and uploading new ones.
 */

import React from 'react';
import { Database, Plus, ExternalLink } from 'lucide-react';

// Predefined list of available datasets with their properties
const datasets = [
  {
    name: "Legal and Compliance Corpus",
    size: "12.7GB",
    type: "Instruction FT",
  },
  {
    name: "Product Catalog",
    size: "1.8GB",
    type: "Instruction FT",
  },
  {
    name: "Math Problems",
    size: "1M-10M",
    type: "Instruction FT",
    link: "https://huggingface.co/datasets/nvidia/OpenMathInstruct-1/tree/main",
  },
  {
    name: "Knowledge Injection - Wiki",
    size: "5GB",
    type: "Continual Pretraining (CPT)",
  },
];

const DatasetsSection = () => {
  return (
    <div className="space-y-6">
      {/* Dataset Upload Section */}
      <div className="bg-white rounded-xl shadow-sm overflow-hidden border border-gray-100 p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-semibold text-gray-900">Upload Dataset</h2>
          {/* New Dataset Button */}
          <button className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-all text-sm font-medium">
            <Plus className="w-4 h-4 mr-2" />
            New Dataset
          </button>
        </div>
        
        {/* Drag and Drop Upload Area */}
        <div className="border-2 border-dashed border-gray-300 rounded-lg p-8 text-center">
          <div className="space-y-4">
            <Database className="w-12 h-12 text-gray-400 mx-auto" />
            <div>
              <p className="text-gray-700">Drag and drop your dataset files here</p>
              <p className="text-sm text-gray-500">or</p>
              <button className="mt-2 text-blue-600 hover:text-blue-700 font-medium">
                Browse files
              </button>
            </div>
            {/* File format information */}
            <p className="text-xs text-gray-500">
              Supported formats: CSV, JSON, JSONL, TXT (max 10GB)
            </p>
          </div>
        </div>
      </div>

      {/* Available Datasets Grid */}
      <div className="bg-white rounded-xl shadow-sm overflow-hidden border border-gray-100">
        <div className="p-6">
          <h2 className="text-2xl font-semibold text-gray-900 mb-6">Available Datasets</h2>
          {/* Dataset Cards Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {datasets.map((dataset, index) => (
              <div
                key={index}
                className="border border-gray-200 rounded-lg p-4 hover:shadow-md transition-shadow"
              >
                {/* Dataset Header with External Link */}
                <div className="flex justify-between items-start mb-3">
                  <h3 className="text-lg font-medium text-gray-900">{dataset.name}</h3>
                  {dataset.link && (
                    <a
                      href={dataset.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:text-blue-700"
                    >
                      <ExternalLink className="w-4 h-4" />
                    </a>
                  )}
                </div>
                {/* Dataset Details */}
                <div className="space-y-2">
                  <div className="flex items-center text-sm text-gray-600">
                    <span className="font-medium mr-2">Size:</span>
                    {dataset.size}
                  </div>
                  <div className="flex items-center text-sm text-gray-600">
                    <span className="font-medium mr-2">Type:</span>
                    {dataset.type}
                  </div>
                </div>
                {/* Dataset Action Button */}
                <div className="mt-4">
                  <button className="w-full px-3 py-2 text-sm text-blue-600 border border-blue-600 rounded-md hover:bg-blue-50 transition-colors">
                    Use Dataset
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DatasetsSection; 