import React from 'react';
import { getAuth } from 'firebase/auth';
import { LogOut } from 'lucide-react';

export default function LogoutButton() {
  const handleLogout = () => {
    const auth = getAuth();
    auth.signOut().then(() => {
      window.location.href = '/login';
    });
  };

  return (
    <button
      onClick={handleLogout}
      className="flex items-center px-3 py-2 text-sm text-gray-600 hover:text-gray-900 transition-colors"
    >
      <LogOut className="w-4 h-4 mr-2" />
      <span>Logout</span>
    </button>
  );
}
