/**
 * FINE-TUNING FORM COMPONENT
 * 
 * A multi-step form for configuring and submitting fine-tuning jobs
 * 
 * FORM STEPS:
 * 1. Fine-tuning Purpose Selection
 *    - Type selection (QnA, Summary, Text Gen, etc.)
 *    - Data source selection (Upload, HuggingFace, etc.)
 * 
 * 2. Training Preferences
 *    - Training location (Local, Cloud, etc.)
 *    - Silicon optimization options
 *    - Modal/Cloud credentials
 * 
 * 3. Model Saving Configuration
 *    - Save format (HF, GGUF, etc.)
 *    - Repository configuration
 *    - Advanced parameters
 *      - Model selection
 *      - Sequence lengths
 *      - Training parameters
 *      - Quantization settings
 * 
 * 4. Evaluation Configuration
 *    - Dataset selection
 *    - Metric selection
 *      - Loss
 *      - Perplexity
 *      - AI Evaluation
 *      - Data Generation
 *    - AI Evaluation metrics
 *      - G-Eval
 *      - Summarization
 *      - Answer Relevancy
 *      - Bias
 *      - Toxicity
 *      - Tool Correctness
 * 
 * 5. Summary & Submission
 *    - Configuration review
 *    - Creation naming
 *    - Final submission
 * 
 * FORM STATE:
 * {
 *   step: number (1-5),
 *   finetuneType: string,
 *   dataSource: string,
 *   selectedDataset: string,
 *   hfApiKey: string,
 *   hfDatasetUrl: string,
 *   trainingLocation: string,
 *   modelSaving: string,
 *   evaluationDataset: File[],
 *   deployToVLLM: boolean,
 *   evaluationMetric: string,
 *   customMetric: string,
 *   optimizeForSilicon: boolean,
 *   modalSecret: string,
 *   modalAccessKey: string,
 *   hfRepoName: string,
 *   advancedOptions: {
 *     model: string,
 *     max_seq_length: number,
 *     generation_length: number,
 *     r: number,
 *     lora_alpha: number,
 *     weight_decay: number,
 *     test_size: number,
 *     logging_steps: number,
 *     lr_scheduler_type: string,
 *     optim: string,
 *     quantization_method: string
 *   },
 *   evaluationMetrics: {
 *     loss: boolean,
 *     perplexity: boolean,
 *     aiEval: boolean,
 *     DataGeneration: boolean,
 *     GenePDF: boolean
 *   },
 *   aiEvalMetrics: {
 *     geval: boolean,
 *     summarisation: boolean,
 *     answerRelevancy: boolean,
 *     bias: boolean,
 *     toxicity: boolean,
 *     toolCorrectness: boolean
 *   }
 * }
 * 
 * FIREBASE INTEGRATION:
 * - Stores form data in Firestore
 * - Associates data with authenticated user
 * - Tracks form completion status
 */

'use client';

import React, { useState, useMemo, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { uploadFile } from "./FileUpload";
import { X } from "lucide-react";
import Header from "./ui/Header";
import {
  Upload,
  ChevronRight,
  ChevronLeft,
  ExternalLink,
  ChevronUp,
  ChevronDown,
  Eye,
  EyeOff,
  HelpCircle,
} from "lucide-react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardActions,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  Switch,
  IconButton,
  InputAdornment,
  Link,
  Divider,
  Tooltip,
  Grid,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { submitFormHandler } from "./submitFormHandler";
import { getAuth } from "firebase/auth";
import { getDatabase, ref, onDisconnect, set, update } from "firebase/database";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from '../firebase/config';

// const DATASETS = [
//   { model: 'nvidia/OpenMathInstruct-1', purpose: 'QnA', topic: 'Math', name: 'Math Problems', link: 'https://huggingface.co/datasets/nvidia/OpenMathInstruct-1', input_col: 'question', output_col: 'generated_solution', instruction_col: 'question' },
//   { model: 'iamtarun/python_code_instructions_18k_alpaca', purpose: 'Text Gen', topic: 'Py Code', name: 'Dataset for finetuning Language model on generating code in python language.', link: 'https://huggingface.co/datasets/iamtarun/python_code_instructions_18k_alpaca', input_col: 'input', output_col: 'output', instruction_col: 'instruction' },
//   { model: 'Amod/mental_health_counseling_conversations', purpose: 'QnA', topic: 'Therapy', name: 'This dataset is a collection of questions and answers sourced from two online counseling and therapy platforms.', link: 'https://huggingface.co/datasets/Amod/mental_health_counseling_conversations', input_col: 'Context', output_col: 'Response', instruction_col: 'Context' },
//   { model: 'qiaojin/PubMedQA', purpose: 'QnA', topic: 'Medical', name: 'The task of PubMedQA is to answer research questions with yes/no/maybe using the corresponding abstracts.', link: 'https://huggingface.co/datasets/qiaojin/PubMedQA', input_col: 'question', output_col: 'long_answer', instruction_col: 'question' },
//   { model: 'deepmind/aqua_rat', purpose: 'QnA', topic: 'Math (Algebra)', name: 'A large-scale dataset consisting of approximately 100,000 algebraic word problems.', link: 'https://huggingface.co/datasets/deepmind/aqua_rat', input_col: 'question', output_col: 'rationale', instruction_col: 'question' },
//   { model: 'umarbutler/open-australian-legal-qa', purpose: 'QnA', topic: 'Legal Aus', name: 'Open Australian Legal QA is the first open dataset of Australian legal questions and answers.', link: 'https://huggingface.co/datasets/umarbutler/open-australian-legal-qa', input_col: 'question', output_col: 'answer', instruction_col: 'question' },
//   { model: 'sujet-ai/Sujet-Finance-Instruct-177k', purpose: 'QnA', topic: 'Finance', name: 'Dataset focused on NER, Sentiment Analysis, and Question Answering in the financial sector.', link: 'https://huggingface.co/datasets/sujet-ai/Sujet-Finance-Instruct-177k', input_col: 'user_prompt', output_col: 'answer', instruction_col: 'system_prompt' },
//   { model: 'ruslanmv/ai-medical-chatbot', purpose: 'QnA', topic: 'Medical', name: 'This is an experimental Dataset designed to run a Medical Chatbot.', link: 'https://huggingface.co/datasets/ruslanmv/ai-medical-chatbot', input_col: 'Patient', output_col: 'Doctor', instruction_col: 'Description' },
//   { model: 'CyberNative/Code_Vulnerability_Security_DPO', purpose: 'QnA', topic: 'Cyber Security', name: 'The Cybernative.ai Code Vulnerability and Security Dataset.', link: 'https://huggingface.co/datasets/CyberNative/Code_Vulnerability_Security_DPO', input_col: 'question', output_col: 'chosen', instruction_col: 'vulnerability' },
  
//   { model: 'Michael22/javadoc', purpose: 'QnA', topic: 'Java', name: 'Java documentation dataset.', link: 'https://huggingface.co/datasets/Michael22/javadoc', input_col: 'input', output_col: 'output', instruction_col: 'instruction' },
//   { model: 'm-a-p/CodeFeedback-Filtered-Instruction', purpose: 'QnA', topic: 'Code', name: 'Filtered code feedback dataset.', link: 'https://huggingface.co/datasets/m-a-p/CodeFeedback-Filtered-Instruction', input_col: 'query', output_col: 'answer', instruction_col: 'query' },
  
//   { model: 'AlfredPros/smart-contracts-instructions', purpose: 'QnA', topic: 'Smart Contracts', name: 'Smart contracts instructions dataset.', link: 'https://huggingface.co/datasets/AlfredPros/smart-contracts-instructions', input_col: 'instruction', output_col: 'source_code', instruction_col: 'instruction' },

//   { model: 'bio-nlp-umass/bioinstruct', purpose: 'Summary', topic: 'Biology', name: 'BioInstruct is a dataset of 25k instructions and demonstrations generated by OpenAI.', link: 'https://huggingface.co/datasets/bio-nlp-umass/bioinstruct', input_col: 'input', output_col: 'output', instruction_col: 'instruction' },
//   { model: 'STEM-AI-mtl/Electrical-engineering', purpose: 'QnA', topic: 'Electrical', name: 'Q&A prompts about electrical engineering, Kicads EDA software features, and Python codes.', link: 'https://huggingface.co/datasets/STEM-AI-mtl/Electrical-engineering?row=3', input_col: 'input', output_col: 'output', instruction_col: 'instruction' },
//   { model: 'jtatman/python-code-dataset-500k', purpose: 'Text Gen', topic: 'Py Code', name: 'Dataset formed through parsing that addresses several points.', link: 'https://huggingface.co/datasets/jtatman/python-code-dataset-500k', input_col: 'instruction', output_col: 'output', instruction_col: 'system' },
//   { model: 'flytech/python-codes-25k', purpose: 'Summary', topic: 'Py Code', name: 'The dataset is a rich source for Python codes and tasks.', link: 'https://huggingface.co/datasets/flytech/python-codes-25k', input_col: 'input', output_col: 'output', instruction_col: 'instruction' },
//   { model: 'FiscalNote/billsum', purpose: 'QnA', topic: 'Law', name: 'BillSum, summarization of US Congressional and California state bills.', link: 'https://huggingface.co/datasets/FiscalNote/billsum', input_col: 'text', output_col: 'summary', instruction_col: 'text' },
//   { model: 'knkarthick/samsum', purpose: 'Summary', topic: 'Linguistics', name: 'The SAMSum dataset contains about 16k messenger-like conversations with summaries.', link: 'https://huggingface.co/datasets/knkarthick/samsum', input_col: 'dialogue', output_col: 'summary', instruction_col: 'summary' },
//   { model: 'Technoculture/synthetic-clinical-notes-embedded', purpose: 'Summary', topic: 'Medical', name: 'This dataset is a post-processed version of starmpcc/Asclepius-Synthetic-Clinical-Notes.', link: 'https://huggingface.co/datasets/Technoculture/synthetic-clinical-notes-embedded?row=9', input_col: 'input', output_col: 'output', instruction_col: 'instruction' },
//   { model: 'ingoziegler/CRAFT-Summarization', purpose: 'Summary', topic: '-', name: 'A synthetic dataset generated with the CRAFT framework.', link: 'https://huggingface.co/datasets/ingoziegler/CRAFT-Summarization', input_col: 'long_but_clean_text', output_col: 'summary', instruction_col: 'instruction' },
//   { model: 'allenai/multinews_sparse_oracle', purpose: 'Summary', topic: 'News', name: 'This is a copy of the Multi-News dataset with modified input sources.', link: 'https://huggingface.co/datasets/allenai/multinews_sparse_oracle', input_col: 'document', output_col: 'summary', instruction_col: 'document' },
//   { model: 'Trelis/big_patent_sample', purpose: 'Summary', topic: 'Patent', name: 'BIGPATENT, consisting of 1.3 million records of U.S. patent documents.', link: 'https://huggingface.co/datasets/Trelis/big_patent_sample?row=3', input_col: 'Description', output_col: 'Abstract', instruction_col: 'Description' },
//   { model: 'wckwan/M4LE', purpose: 'Summary', topic: 'Airplane paper Eval', name: 'M4LE is a Multi-ability, Multi-range, Multi-task benchmark for long-context evaluation.', link: 'https://huggingface.co/datasets/wckwan/M4LE', input_col: 'input', output_col: 'answers', instruction_col: 'instruction' },
//   { model: 'xz97/MedInstruct', purpose: 'Text Gen', topic: 'Medical', name: 'MedInstruct-52k is used for instruction-tuning of language models.', link: 'https://huggingface.co/datasets/xz97/MedInstruct?row=0', input_col: 'instruction', output_col: 'gpt-4-answer', instruction_col: 'instruction' },
//   { model: 'FreedomIntelligence/ApolloCorpus', purpose: 'Text Gen', topic: 'Medical', name: 'Multilingual Medicine dataset.', link: 'https://huggingface.co/datasets/FreedomIntelligence/ApolloCorpus', input_col: 'text', output_col: 'text', instruction_col: 'text' },
// ]



const DATASETS = [
  { model: 'iamtarun/python_code_instructions_18k_alpaca', purpose: 'QnA', topic: 'Py Code', name: 'A dataset of Python programming instructions and solutions for code generation tasks.', link: 'https://huggingface.co/datasets/iamtarun/python_code_instructions_18k_alpaca', input_col: 'input', output_col: 'output', instruction_col: 'instruction' },
  { model: 'Amod/mental_health_counseling_conversations', purpose: 'QnA', topic: 'Therapy', name: 'A collection of mental health counseling dialogues for improving therapy and empathetic conversation systems.', link: 'https://huggingface.co/datasets/Amod/mental_health_counseling_conversations', input_col: 'Context', output_col: 'Response', instruction_col: "Context" },
  { model: 'bio-nlp-umass/bioinstruct', purpose: 'QnA', topic: 'Biology', name: 'A biology-specific instruction dataset with 25k examples for multi-task learning in medical, clinical, and healthcare NLP.', link: 'https://huggingface.co/datasets/bio-nlp-umass/bioinstruct', input_col: 'input', output_col: 'output', instruction_col: 'instruction' },
  // { model: 'FreedomIntelligence/medical-o1-verifiable-problem', purpose: 'QnA', topic: 'Medical', name: 'A dataset of open-ended verifiable medical questions and answers for diagnostic and educational use.', link: 'https://huggingface.co/datasets/FreedomIntelligence/medical-o1-verifiable-problem', input_col: 'Open-ended Verifiable Question', output_col: 'Ground-True Answer', instruction_col: 'Open-ended Verifiable Question' },
  { model: 'Malikeh1375/medical-question-answering-datasets', purpose: 'QnA', topic: 'Medical', name: 'A dataset comprising diverse medical Q&A for diagnosis, consultation, and healthcare Language Model Training.', link: 'https://huggingface.co/datasets/Malikeh1375/medical-question-answering-datasets?row=5', input_col: 'input', output_col: 'output', instruction_col: 'instruction' },
  { model: 'knkarthick/samsum', purpose: 'Summary', topic: 'Linguistics', name: 'A conversational summarization dataset of over 16k dialogues for creating concise summaries of text conversations.', link: 'https://huggingface.co/datasets/knkarthick/samsum', input_col: 'dialogue', output_col: 'summary', instruction_col: "dialogue" },
  { model: 'allenai/multinews_sparse_oracle', purpose: 'Summary', topic: 'News', name: 'A dataset of news articles and their summaries, optimized for sparse oracle summarization tasks.', link: 'https://huggingface.co/datasets/allenai/multinews_sparse_oracle', input_col: 'document', output_col: 'summary', instruction_col: "document" },
  { model: 'Trelis/big_patent_sample', purpose: 'Summary', topic: 'Patent', name: 'A dataset of sampled U.S. patent descriptions and their human-written abstractive summaries for patent text summarization tasks.', link: 'https://huggingface.co/datasets/Trelis/big_patent_sample?row=3', input_col: 'description', output_col: 'abstract', instruction_col: "description" },
  { model: 'Sp1786/multiclass-sentiment-analysis-dataset', purpose: 'Sentiment Analysis', topic: 'Text Messages', name: 'A dataset for multiclass sentiment analysis tasks, with labeled text data for positive, negative, and neutral sentiments.', link: 'https://huggingface.co/datasets/Sp1786/multiclass-sentiment-analysis-dataset?row=6', input_col: 'text', output_col: 'sentiment', instruction_col: "text" },
  { model: 'juanka0357/bitcoin-sentiment-analysis', purpose: 'Sentiment Analysis', topic: 'Bitcoin', name: 'A dataset for sentiment analysis of Bitcoin-related tweets to classify opinions as positive, negative, or neutral.', link: 'https://huggingface.co/datasets/juanka0357/bitcoin-sentiment-analysis', input_col: 'input', output_col: 'output', instruction_col: 'instruction' },
  { model: 'llm-wizard/Product-Descriptions-and-Ads', purpose: 'Text Gen', topic: 'Product Description', name: 'A dataset featuring product descriptions and advertisements tailored for e-commerce and marketing text generation tasks.', link: 'https://huggingface.co/datasets/llm-wizard/Product-Descriptions-and-Ads?row=21', input_col: 'product', output_col: 'description', instruction_col: 'ad' }
];


const defaultAdvancedOptions = {
  model: "mistralai/Mistral-7B-v0.1",
  output_dir: "./results",
  per_device_train_batch_size: 4,
  per_device_eval_batch_size: 4,
  gradient_accumulation_steps: 4,
  Epochs: 1,
  "Learning Rate": 2e-4,
  fp16: true,
  logging_dir: "./logs",
  remove_unused_columns: false,
};

const EvalTooltips = {
  geval:
    "G-Eval is a general-purpose evaluation framework for language models.",
  summarisation:
    "Evaluates the model's ability to generate concise and accurate summaries.",
  answerRelevancy:
    "Assesses how relevant and accurate the model's answers are to given questions.",
  bias: "Detects and measures potential biases in the model's outputs.",
  toxicity:
    "Evaluates the model's tendency to generate toxic or inappropriate content.",
  // toolCorrectness:
  //   "Assesses the accuracy of the model in using tools or calling functions.",
  loss: "Loss is an assessment of how close the model's predictions are to the expected output sequence",
  perplexity:
    "Perplexity measures how confident the model is in its predictions and how well it understands the language. Lower is better",
};

const params = {
  model:
    "Model: The pretrained model you want to fine-tune. Different models vary in size and training focus.",
  "Max Sequence Length":
    "Max Sequence Length: The maximum number of tokens (words or characters) in each input sequence. Longer lengths allow more context but increase computation.",
  "Generation Length":
    "Generation Length: The number of tokens the model generates in response to a prompt. Higher values generate longer responses.",
  Rank: "Rank: Controls the number of trainable parameters in fine-tuning. Smaller values (e.g., 4-8) use fewer resources and are suitable for simpler tasks. Larger values (e.g., 16-32) increase adaptability for complex tasks but require more memory. Default: 8. Adjust based on task complexity and hardware capacity.",
  "Lora Alpha":
    "Lora Alpha: Determines the strength of fine-tuned weight updates relative to the base model. Choose:\n 1 (Default): Balanced for most tasks, including small datasets or simple applications.\n 2: Increases adaptation strength, ideal for complex tasks or large datasets requiring stronger fine-tuning. Use cautiously if training becomes unstable. ",
  "Weight Decay":
    "Weight Decay: Controls the penalty applied to model weights during fine-tuning to prevent overfitting. Recommended range: 0.0 - 0.1 (default: 0.01). Use higher values (e.g., 0.01 - 0.1) for smaller datasets to encourage generalization and lower values (e.g., 0.001 - 0.01) for larger datasets. Setting 0.0 disables regularization but may increase the risk of overfitting. Choose carefully based on your dataset size and task.",
  "Evaluation Split":
    "Evaluation Split: The proportion of data reserved for evaluation. 1 means that 1% of the data is used to evaluate the model.",
  "Logging Steps":
    "Logging Steps: Frequency of logging the model's performance metrics. Lower values provide more frequent updates but increase overhead.",
  "Learning Rate Scheduler Type":
    "Learning Rate Scheduler Type: Strategy for adjusting the learning rate during training. 'Linear' gradually decreases the rate as training progresses.",
    Optimizer:
    "Optimizer: The optimization algorithm used for training. 'adamw_8bit' reduces memory usage and is suitable for large models.",
  "Quantization Method":
    "Quantization Method: Reduces model size and memory usage by representing weights in lower precision. 'Quantized' is a recommended option for most cases.",
  "Learning Rate":
    "Learning Rate: Determines the rate of parameter updates during training. Recommended range depends on task and model size. Higher values (e.g., >2e-4) enable faster convergence but risk instability. Lower values (e.g., <2e-4) provide stable, precise updates but may slow down training. Default: 2e-4. Adjust based on your dataset and training stability.",
  "Epochs":
    "Epochs: The number of times the entire dataset is passed through the model during training. Higher values (e.g., 3-5) allow the model to learn more from the data but may increase training time. Lower values (e.g., 1-2) reduce training time but may limit the model's ability to learn. Default: 1. Adjust based on your dataset size and training stability.",


};

// const onSubmit = (data) => {
//   console.log('Submitting form data:', data); // For debugging

//   // Call the submitFormHandler function and pass the form data
//   // submitFormHandler(data);

  
// };

export default function Component() {
  const navigate = useNavigate();
  const [hasExistingRuns, setHasExistingRuns] = React.useState(false);

  React.useEffect(() => {
    const checkExistingRuns = async () => {
      try {
        const auth = getAuth();
        const user = auth.currentUser;
        if (user) {
          
          // Check the admin document for user's runs
          const adminDocRef = doc(db, "Runs",  user.uid);   
          const adminDoc = await getDoc(adminDocRef);
          if (adminDoc.exists()) {
            const data = adminDoc.data();
            const subCollections = data.subCollections || [];
            setHasExistingRuns(subCollections.includes(user.uid));
          }
        }
      } catch (error) {
        console.error("Error checking existing runs:", error);
      }
    };

    checkExistingRuns();
  }, []);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log(`FineTuningForm: User logged in - ${user.email}`);
      } else {
        console.log("FineTuningForm: No user is logged in");
      }
    });

    return () => unsubscribe(); // Cleanup
  }, []);

  const methods = useForm({
    defaultValues: {
      creationName: "",
      step: 1,
      finetuneType: "",
      instructionCol:'',
      dataSource: "",
      selectedDataset: "",
      hfApiKey: "",
      hfDatasetUrl: "",
      trainingLocation: "",
      modelSaving: "",
      validationDataset: null,
      deployToVLLM: false,
      evaluationMetric: "",
      customMetric: "",
      optimizeForSilicon: false,
      modalSecret: "",
      modalAccessKey: "",
      hfRepoName: "",
      showAdvancedParams: false,
      advancedOptions: {
        model: "unsloth/Phi-3.5-mini-instruct",
        Epochs: 1,
        "Max Sequence Length": 2048,
        "Generation Length": 1024,
        Rank: 16,
        "Lora Alpha": 1,
        "Weight Decay": 0.01,
        "Evaluation Split": 0.005,
        "Logging Steps": 25,
        "Learning Rate Scheduler Type": "linear",
        Optimizer: "adamw_8bit",
        "Quantization Method": "quantized",
        "Learning Rate": 2e-4,
      },
      evaluationDatasetType: "",
      Eval_HF_URL: "",
      evaluationMetrics: {
        loss: true,
        perplexity: false,
        aiEval: false,
        DataGeneration: false,
        GenePDF: false,
      },
      openAiApiKey: "",
      showOpenAiApiKey: false,
      aiEvalMetrics: {
        geval: true,
        summarisation: false,
        answerRelevancy: false,
        bias: false,
        toxicity: false,
        // toolCorrectness: false,
      },
    },
  });

  const { watch, setValue, handleSubmit, getValues } = methods;
  const formValues = watch();
  const [showApiKey, setShowApiKey] = useState(false);
  const [showHfApiKey, setShowHfApiKey] = useState(false);
  const [formState, setFormState] = useState({});

  const filteredDatasets = React.useMemo(() => {
    return DATASETS.filter(
      (dataset) => dataset.purpose === formValues.finetuneType
    );
  }, [formValues.finetuneType]);

  const selectedDataset = React.useMemo(() => {
    return DATASETS.find(
      (dataset) => dataset.model === formValues.selectedDataset
    );
  }, [formValues.selectedDataset]);

  const handleInputChange = (field, value) => {
    setValue(field, value);
    console.log(`Input Change - ${field}:`, value);
    console.log("Current formValues:", getValues());

    if (field === "finetuneType") {
      if (["Summary", "Text Gen", "Sentiment Analysis"].includes(value)) {
        setValue("evaluationDatasetType", "use-training");
        setValue("generateEvaluationDataset", false); // Ensure switch is off
      }
    }

    if (field === "dataSource") {
      if (value === "provided") {
        // Clear Hugging Face specific fields
          setValue("hfDatasetUrl", "");
          setValue("hfApiKey", "");
          setValue("inputCol", "");
          setValue("instructionCol", "");
          setValue("outputCol", "");
      } else if (value === "huggingface") {
        setValue("hfDatasetUrl", "");
        setValue("hfApiKey", "");
        setValue("inputCol", "");
        setValue("instructionCol", "");
        setValue("outputCol", "");
        setValue("selectedDataset", "");
        
      }
    }

    if (field === "modelSaving") {
      if (['convert-gguf-local', 'download'].includes(value)) {
        setValue("hfRepoName", "");
      }
    }

    // Reset Eval_HF_URL when toggling evaluationDatasetType
    if (field === "evaluationDatasetType") {
      setValue("Eval_HF_URL", "");
    }
  };

  const handleAdvancedParamChange = (option, value) => {
    
    setValue(`advancedOptions.${option}`, value)
  }

  const handleFileUpload = (event, field) => {
    event.preventDefault()
    const files = event.dataTransfer ? event.dataTransfer.files : event.target.files

    if (files && files.length > 0) {
      const validFiles = Array.from(files).filter(file => file.size <= 10 * 1024 * 1024) // 10MB limit
      if (validFiles.length > 0) {
        console.log(`${field} uploaded:`, validFiles.map(f => f.name).join(', '))
        handleInputChange(field, validFiles)
      } else {
        alert('All files exceed the 10MB limit')
      }
    }
  }

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleMetricChange = (metric) => {
    setValue(`evaluationMetrics.${metric}`, !formValues.evaluationMetrics[metric])
  }

  const handleAiEvalMetricChange = (metric) => {
    if (formValues.finetuneType === "Summary" && metric !== "summarisation") {
        return; // Prevent toggling any metric except Summarisation when Summary is chosen
    }
    setValue(`aiEvalMetrics.${metric}`, !formValues.aiEvalMetrics[metric]);
};

  const handleSaveProfile = () => {
    //setIsEditing(false);
    // Add your save logic here
  };

  const { step } = formValues;

  const validateStep = (step) => {
    if (step === 1) {
      if (formValues.dataSource === 'provided') {
        return formValues.finetuneType && formValues.selectedDataset;
      } else if (formValues.dataSource === 'huggingface') {
        return formValues.finetuneType &&
               formValues.hfDatasetUrl &&
               formValues.hfApiKey &&
               formValues.inputCol &&
               formValues.instructionCol &&
               formValues.outputCol;
      }
      return false; // If no valid data source is selected
    }

    if (step === 2) {
      return formValues.trainingLocation && formValues.modalSecret && formValues.modalAccessKey;
    }

    if (step === 3) {
      if (!formValues.modelSaving) {
        alert("Please select a model saving option before proceeding.");
        return false;
      }
      if (['save-hf', 'convert-gguf-hf'].includes(formValues.modelSaving)) {
        return formValues.hfRepoName && formValues.hfApiKey;
      }
      return true;
    }

    if (step === 4) {
      if (!formValues.evaluationDatasetType) {
        alert("Please select an evaluation dataset type before proceeding.");
        return false;
      }
      if (formValues.evaluationDatasetType === 'huggingface') {
        if (!formValues.Eval_HF_URL || !formValues.hfApiKey || !formValues.openAiApiKey) {
          alert("Please fill in all required fields for Hugging Face evaluation before proceeding.");
          return false;
        }
        if (formValues.evaluationMetrics.aiEval) {
          const isAnyAiEvalMetricChecked = Object.values(formValues.aiEvalMetrics).some(value => value);
          if (!formValues.openAiApiKey || !isAnyAiEvalMetricChecked) {
            alert("Please provide an OpenAI API key and select at least one AI-Eval metric before proceeding.");
            return false;
          }
        }
        return true;
      }
      if (formValues.evaluationDatasetType === 'use-training') {
        if (formValues.generateEvaluationDataset && !formValues.openAiApiKey) {
          alert("Please provide an OpenAI API key for enhancing with synthetic data.");
          return false;
        }
        if (formValues.evaluationMetrics.aiEval) {
          const isAnyAiEvalMetricChecked = Object.values(formValues.aiEvalMetrics).some(value => value);
          if (!formValues.openAiApiKey || !isAnyAiEvalMetricChecked) {
            alert("Please provide an OpenAI API key and select at least one AI-Eval metric before proceeding.");
            return false;
          }
        }
        return true;
      }
      return true;
    }

    const requiredFields = {
      2: ['trainingLocation', 'modalSecret', 'modalAccessKey'],
    };

    const fieldsToCheck = requiredFields[step] || [];
    return fieldsToCheck.every(field => formValues[field]);
  };

  const handleNextStep = () => {
    if (validateStep(formValues.step)) {
      handleInputChange("step", formValues.step + 1);
    } else {
      alert("Please fill in all required fields before proceeding.");
    }
  };

  // Ensure aiEvalMetrics are set correctly when finetuneType is "Summary"
  useEffect(() => {
    if (formValues.finetuneType === "Summary") {
        setValue("aiEvalMetrics.summarisation", true); // Force Summarisation to be selected
        Object.keys(formValues.aiEvalMetrics).forEach((key) => {
            if (key !== "summarisation") {
                setValue(`aiEvalMetrics.${key}`, false); // Disable all other metrics
            }
        });
    }
    else {
      setValue("aiEvalMetrics.summarisation", false); // Disable Summarisation when not Summary
    } 
}, [formValues.finetuneType]);

  return (
    <FormProvider {...methods}>
      <Box
        sx={{
          minHeight: "100vh",
          bgcolor: "rgb(219,234,254)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Header showDashboardButton={hasExistingRuns} />
        <Box
          sx={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            p: 4,
          }}
        >
          <Card
            sx={{
              width: "100%",
              maxWidth: "1000px",
              borderRadius: 4,
              boxShadow:
                "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1)",
              p: 6,
            }}
          >
            <CardContent sx={{ p: 2 }}>
              <Typography
                variant="h5"
                gutterBottom
                sx={{ color: "rgb(30,58,138)", fontWeight: "bold", mb: 4 }}
              >
                {formValues.step === 1 &&
                  "What would you like to finetune the model for?"}
                {formValues.step === 2 && "Finetuning Preferences"}
                {formValues.step === 3 && "Model Saving and Conversion"}
                {formValues.step === 4 && "Evaluation  Management"}
                {formValues.step === 5 && "Form Submission Summary"}
              </Typography>
              {formValues.step === 1 && (
                <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
                  <FormControl fullWidth required>
                    <InputLabel id="finetune-type-label">
                      Select a Fine-Tuning Purpose
                    </InputLabel>
                    <Select
                      labelId="finetune-type-label"
                      value={formValues.finetuneType}
                      onChange={(e) =>
                        handleInputChange("finetuneType", e.target.value)
                      }
                      label="Select a fine-tuning  type"
                      required
                    >
                      <MenuItem value="QnA">Question Answering</MenuItem>
                      <MenuItem value="Summary">Summarization</MenuItem>
                      <MenuItem value="Text Gen">Text Generation</MenuItem>
                      <MenuItem value="Sentiment Analysis">
                        Sentiment Analysis
                      </MenuItem>
                      {/* <MenuItem value="Tool/Function Calling">
                        Tool/Function Calling
                      </MenuItem> */}
                    </Select>
                  </FormControl>

                  {formValues.finetuneType && (
                    <Box
                      sx={{ display: "flex", flexDirection: "column", gap: 3 }}
                    >
                      <Typography variant="h6">Select a Data Source</Typography>
                      <FormControl fullWidth>
                        <InputLabel id="data-source-label">
                          Select data source
                        </InputLabel>
                        <Select
                          labelId="data-source-label"
                          value={formValues.dataSource}
                          onChange={(e) =>
                            handleInputChange("dataSource", e.target.value)
                          }
                          label="Select data source"
                          required
                        >
                          {filteredDatasets.length > 0 && (
                            <MenuItem value="provided">
                              Choose from provided datasets
                            </MenuItem>
                          )}
                          <MenuItem value="upload" disabled>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                              Upload your own dataset (CSV/Excel, max 10MB)
                              <span style={{
                                backgroundColor: 'rgb(219,234,254)',
                                color: 'rgb(29,78,216)',
                                padding: '2px 8px',
                                borderRadius: '12px',
                                fontSize: '0.75rem',
                                fontWeight: 'medium',
                                marginLeft: '8px'
                              }}>
                                Coming Soon
                              </span>
                            </Box>
                          </MenuItem>
                          <MenuItem value="huggingface">
                            Use your own Hugging Face dataset
                          </MenuItem>
                        </Select>
                      </FormControl>

                      {formValues.dataSource === 'provided' && filteredDatasets.length > 0 && (
                        <Box sx={{ mt: 2 }}>
                          <FormControl fullWidth required>
                            <InputLabel id="dataset-label">Select a dataset</InputLabel>
                            <Select
                              labelId="dataset-label"
                              value={formValues.selectedDataset}
                              onChange={(e) => {
                                handleInputChange('selectedDataset', e.target.value);
                                const selected = filteredDatasets.find(dataset => dataset.model === e.target.value);
                                if (selected) {
                                  handleInputChange('inputCol', selected.input_col || "input");
                                  handleInputChange('outputCol', selected.output_col || "output");
                                  handleInputChange('instructionCol', selected.instruction_col || "instruction");
                                }
                              }}
                              label="Select a dataset"
                              required
                            >
                              {filteredDatasets.map((dataset) => (
                                <MenuItem key={dataset.model} value={dataset.model}>
                                  {dataset.topic} - {dataset.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                          {selectedDataset && (
                            <Card sx={{ mt: 2 }}>
                              <CardContent>
                                <Typography variant="h6" sx={{ mb: 1 }}>Topic: {selectedDataset.topic}</Typography>
                                <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>Name: {selectedDataset.name}</Typography>
                                <Link
                                  href={selectedDataset.link}
                                  target="_blank"
                                  rel="noopener"
                                  sx={{ display: 'flex', alignItems: 'center', mt: 1 }}
                                >
                                  View Dataset on Hugging Face <ExternalLink size={16} sx={{ ml: '0.25rem' }} />
                                </Link>
                              </CardContent>
                            </Card>
                          )}
                        </Box>
                      )}

                      {/* {formValues.dataSource === "upload" && (
                        <Box
                          sx={{
                            mt: 2,
                            border: "2px dashed",
                            borderColor: "grey.300",
                            borderRadius: 2,
                            p: 4,
                            textAlign: "center",
                            cursor: "pointer",
                          }}
                          onDrop={(e) =>
                            handleFileUpload(e, "validationDataset")
                          }
                          onDragOver={handleDragOver}
                          onClick={() =>
                            document.getElementById("file-upload").click()
                          }
                        >
                          <Upload
                            size={48}
                            style={{ color: "#888", margin: "0 auto" }}
                          />
                          <Typography variant="body1" sx={{ mt: 2 }}>
                            Click to upload or drag and drop
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            CSV or Excel files only, max 10MB
                          </Typography>
                          <input
                            id="file-upload"
                            type="file"
                            style={{ display: "none" }}
                            accept=".csv,.xlsx,.xls"
                            onChange={(e) =>
                              handleFileUpload(e, "validationDataset")
                            }
                          />
                        </Box>
                      )} */}

                      {formValues.dataSource === "huggingface" && (
                        <Box
                          sx={{
                            mt: 2,
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                          }}
                        >
                          <TextField
                            label="Hugging Face Dataset Name"
                            type="url"
                            value={formValues.hfDatasetUrl}
                            onChange={(e) =>
                              handleInputChange("hfDatasetUrl", e.target.value)
                            }
                            placeholder="Enter the Hugging Face dataset URL"
                            fullWidth
                            required
                          />
                          <TextField
                            label="Hugging Face API Key"
                            type={formValues.showHfApiKey ? "text" : "password"}
                            value={formValues.hfApiKey}
                            onChange={(e) =>
                              handleInputChange("hfApiKey", e.target.value)
                            }
                            placeholder="Enter your Hugging Face API key"
                            fullWidth
                            required
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() =>
                                      handleInputChange(
                                        "showHfApiKey",
                                        !formValues.showHfApiKey
                                      )
                                    }
                                    edge="eye"
                                  >
                                    {formValues.showHfApiKey ? (
                                      <VisibilityOff size={20} />
                                    ) : (
                                      <Visibility size={20} />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />



                          <Box>
                          <TextField
                            label="Input Column"
                            type="text"
                            value={formValues.inputCol}
                            onChange={(e) => handleInputChange('inputCol', e.target.value)}
                            placeholder="Enter your input column"
                            fullWidth
                            required
                          />
                          <Tooltip title="Column relating to input sequence passed into your model" arrow placement="top"  >
                                    <IconButton size="small" sx={{ ml: 1, p: 0 }}>
                                      <HelpCircle size={16} />
                                    </IconButton>
                          </Tooltip>
                          </Box>





                          <Box>

                          <TextField
                            label="Instruction Column"
                            type="text"
                            value={formValues.instructionCol}
                            onChange={(e) => handleInputChange('instructionCol', e.target.value)}
                            placeholder="Enter your instruction column"
                            fullWidth
                            required
                          />


                          <Tooltip title="Column relating to a system prompt that you start your model with. If you don't have one, use the same column name as the input column" arrow placement="top"  >
                                    <IconButton size="small" sx={{ ml: 1, p: 0 }}>
                                      <HelpCircle size={16} />
                                    </IconButton>
                          </Tooltip>

                          </Box>
                          
                          <Box>
                          <TextField
                            label="Output Column"
                            type="text"
                            value={formValues.outputCol}
                            onChange={(e) => handleInputChange('outputCol', e.target.value)}
                            placeholder="Enter your output column"
                            fullWidth
                            required
                          />
                          <Tooltip title="Column relating to output sequence that you expect your model to generate" arrow placement="top"  >
                                    <IconButton size="small" sx={{ ml: 1, p: 0 }}>
                                      <HelpCircle size={16} />
                                    </IconButton>
                          </Tooltip>
                            </Box>
                          
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
              )}

              {formValues.step === 2 && (
                <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                  <Typography variant="h6">
                    Where would you like to conduct finetuning?
                  </Typography>
                  <RadioGroup
                    value={formValues.trainingLocation}
                    onChange={(e) =>
                      handleInputChange("trainingLocation", e.target.value)
                    }
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <FormControlLabel
                        value="local"
                        control={<Radio disabled />}
                        label="Locally (Optimised for GPU/Mac Silicon)"
                      />
                      <span style={{
                        backgroundColor: 'rgb(219,234,254)',
                        color: 'rgb(29,78,216)',
                        padding: '2px 8px',
                        borderRadius: '12px',
                        fontSize: '0.75rem',
                        fontWeight: 'medium',
                      }}>
                        Coming Soon
                      </span>
                    </Box>
                    <FormControlLabel
                      value="cloud_modal"
                      control={<Radio />}
                      label="Cloud (Modal)"
                    />
                  </RadioGroup>

                  {formValues.trainingLocation === "cloud_modal" && (
                    <Box sx={{ mt: 2, ml: 4, display: "flex", flexDirection: "column", gap: 2 }}>
                      <TextField
                        label="Modal Secret"
                        type={formValues.showModalSecret ? "text" : "password"}
                        value={formValues.modalSecret}
                        onChange={(e) => handleInputChange("modalSecret", e.target.value)}
                        placeholder="Enter your Modal secret"
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle secret visibility"
                                onClick={() => handleInputChange("showModalSecret", !formValues.showModalSecret)}
                                edge="end"
                              >
                                {formValues.showModalSecret ? <VisibilityOff size={20} /> : <Visibility size={20} />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <TextField
                        label="Modal Token ID"
                        type={formValues.showModalAccessKey ? "text" : "password"}
                        value={formValues.modalAccessKey}
                        onChange={(e) => handleInputChange("modalAccessKey", e.target.value)}
                        placeholder="Enter your Modal access key"
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle access key visibility"
                                onClick={() => handleInputChange("showModalAccessKey", !formValues.showModalAccessKey)}
                                edge="end"
                              >
                                {formValues.showModalAccessKey ? <VisibilityOff size={20} /> : <Visibility size={20} />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  )}
                </Box>
              )}

              {formValues.step === 3 && (
                <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                  <RadioGroup
                    value={formValues.modelSaving}
                    onChange={(e) =>
                      handleInputChange("modelSaving", e.target.value)
                    }
                  >
                    <FormControlLabel
                      value="save-hf"
                      control={<Radio />}
                      label="Save safetensors to Hugging Face"
                    />
                    <FormControlLabel
                      value="convert-gguf-hf"
                      control={<Radio />}
                      label="Push model in GGUF Format to Hugging Face"
                    />
                    <FormControlLabel
                      value="convert-gguf-local"
                      control={<Radio />}
                      label="Download In GGUF Format"
                    />
                    <FormControlLabel
                      value="download"
                      control={<Radio />}
                      label="Download model + adapter"
                    />
                    <FormControlLabel
                      value="Download VLLM Implementation"
                      control={<Radio />}
                      label={
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          Download VLLM Implementation
                          <span style={{
                            backgroundColor: 'rgb(219,234,254)',
                            color: 'rgb(29,78,216)',
                            padding: '2px 8px',
                            borderRadius: '12px',
                            fontSize: '0.75rem',
                            fontWeight: 'medium',
                            marginLeft: '8px'
                          }}>
                            Coming Soon
                          </span>
                        </Box>
                      }
                      disabled={true}
                      labelPlacement="end"
                    />
                  </RadioGroup>
                  {(formValues.modelSaving === "save-hf" ||
                    formValues.modelSaving === "convert-gguf-hf") && (
                    <Box
                      sx={{
                        mt: 2,
                        ml: 4,
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                      }}
                    >
                      <TextField
                        label="Repository Name"
                        type="text"
                        value={formValues.hfRepoName}
                        onChange={(e) =>
                          handleInputChange("hfRepoName", e.target.value)
                        }
                        placeholder="Enter repository name"
                        fullWidth
                      />
                      <TextField
                        label="Hugging Face API Key"
                        type={formValues.showHfApiKey ? "text" : "password"}
                        value={formValues.hfApiKey}
                        onChange={(e) =>
                          handleInputChange("hfApiKey", e.target.value)
                        }
                        placeholder="Enter your Hugging Face API key"
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                  handleInputChange(
                                    "showHfApiKey",
                                    !formValues.showHfApiKey
                                  )
                                }
                                edge="end"
                              >
                                {formValues.showHfApiKey ? (
                                  <VisibilityOff size={20} />
                                ) : (
                                  <Visibility size={20} />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  )}
                  <Box
                    sx={{ mt: 4, borderTop: 1, borderColor: "divider", pt: 4 }}
                  >
                    <Button
                      onClick={() =>
                        handleInputChange(
                          "showAdvancedParams",
                          !formValues.showAdvancedParams
                        )
                      }
                      endIcon={
                        formValues.showAdvancedParams ? (
                          <ChevronUp size={20} />
                        ) : (
                          <ChevronDown size={20} />
                        )
                      }
                      sx={{ mb: 2 }}
                    >
                      Advanced Parameters
                    </Button>
                    {formValues.showAdvancedParams && (
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "repeat(2, 1fr)",
                          gap: 2,
                        }}
                      >
                        <FormControl fullWidth>
                          <InputLabel id="model-label">
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              Model
                              <Tooltip
                                title={params["model"]}
                                arrow
                                placement="top"
                              >
                                <IconButton size="small" sx={{ ml: 1, p: 0 }}>
                                  <HelpCircle size={16} />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </InputLabel>
                          <Select
                            labelId="model-label"
                            value={formValues.advancedOptions.model}
                            onChange={(e) =>
                              handleAdvancedParamChange("model", e.target.value)
                            }
                            label={<Box sx={{ display: "flex", alignItems: "center" }}>Model</Box>}
                          >
                            <MenuItem value="unsloth/tinyllama-chat-bnb-4bit">
                              TinyLlama 
                            </MenuItem>
                            <MenuItem value="unsloth/gemma-2-2b-it-bnb-4bit">
                              Gemma 2.2B Instruct
                            </MenuItem>
                            <MenuItem value="unsloth/Phi-3.5-mini-instruct">
                              Phi 3.5 Mini Instruct
                            </MenuItem>
                            <MenuItem value="unsloth/SmolLM2-360M-Instruct-bnb-4bit">
                              SmolLM2 360M Instruct Bnb 4bit
                            </MenuItem>
                          </Select>
                        </FormControl>
                        {Object.entries(formValues.advancedOptions).map(
                          ([key, value]) => {
                            // Skip rendering for 'model' and 'quantization_method' keys
                            if (key === "model" || key === "Quantization Method") {
                              return null;
                            }

                            const isIntegerField = [
                              "Max Sequence Length",
                              "Generation Length",
                              "Lora Alpha",
                              "Rank",
                              "Logging Steps",
                            ].includes(key);

                            if (key === "Learning Rate Scheduler Type") {
                              return (
                                <FormControl fullWidth key={key}>
                                  <InputLabel id={`${key}-label`}>
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                      {key}
                                      <Tooltip title={params[key]} arrow placement="top">
                                        <IconButton size="small" sx={{ ml: 1, p: 0 }}>
                                          <HelpCircle size={16} />
                                        </IconButton>
                                      </Tooltip>
                                    </Box>
                                  </InputLabel>
                                  <Select
                                    labelId={`${key}-label`}
                                    value={value}
                                    onChange={(e) => handleAdvancedParamChange(key, e.target.value)}
                                    label={key}
                                  >
                                    <MenuItem value="linear">Linear</MenuItem>
                                    <MenuItem value="cosine">Cosine</MenuItem>
                                    <MenuItem value="polynomial">Polynomial</MenuItem>
                                  </Select>
                                </FormControl>
                              );
                            } else if (key === "Optimizer") {
                              return (
                                <TextField
                                  key={key}
                                  label={
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {key}
                                      <Tooltip
                                        title={params[key]}
                                        arrow
                                        placement="top"
                                      >
                                        <IconButton
                                          size="small"
                                          sx={{ ml: 1, p: 0 }}
                                        >
                                          <HelpCircle size={16} />
                                        </IconButton>
                                      </Tooltip>
                                    </Box>
                                  }
                                  value={value}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  variant="filled"
                                />
                              );
                            } 
                            else if (key === "Evaluation Split") {
                              return (
                                <TextField
                                  fullWidth
                                  key={key}
                                  label={
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                      {key}
                                      <Tooltip title={params[key]} arrow placement="top">
                                        <IconButton size="small" sx={{ ml: 1, p: 0 }}>
                                          <HelpCircle size={16} />
                                        </IconButton>
                                      </Tooltip>
                                    </Box>
                                  }
                                  type="number"
                                  value={value ? (value * 100).toFixed(1) : '5.0'} // Ensures default value is set to 5%
                                  onChange={(e) => {
                                    let inputVal = e.target.value;
                                    let newValue = parseFloat(inputVal);
                                    if (isNaN(newValue) || newValue < 0.5) newValue = 0.5; // Default to 0.5 if input is not a number or less than 0.5
                                    newValue = Math.min(newValue, 20); // Force value to be less than or equal to 5
                                    handleAdvancedParamChange(key, newValue / 100); // Convert back to a fraction for internal state management
                                  }}
                                  InputProps={{
                                    inputProps: {
                                      step: 0.1,
                                      min: 0.5,
                                      max: 20,
                                    }
                                  }}
                                  variant="outlined"
                                />
                              );
                            } else if (key === "Weight Decay") {
                              return (
                                <TextField
                                  key={key}
                                  label={
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {key}
                                      <Tooltip
                                        title={params[key]}
                                        arrow
                                        placement="top"
                                      >
                                        <IconButton
                                          size="small"
                                          sx={{ ml: 1, p: 0 }}
                                        >
                                          <HelpCircle size={16} />
                                        </IconButton>
                                      </Tooltip>
                                    </Box>
                                  }
                                  type="number"
                                  value={value}
                                  onChange={(e) => {
                                    let inputVal = e.target.value;
                                    let newValue = inputVal === '' ? 0 : parseFloat(inputVal);
                                    newValue = Math.max(0, Math.min(newValue, 0.1)); // Ensure value is between 0.0 and 0.1
                                    handleAdvancedParamChange(key, newValue);
                                  }}
                                  InputProps={{
                                    inputProps: {
                                      step: 0.001,
                                      min: 0.0,
                                      max: 0.1,
                                    }
                                  }}
                                  variant="outlined"
                                />
                              );
                            } else if (key === "Learning Rate") {
                              return (
                                <TextField
                                  key={key}
                                  label={
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {key}
                                      <Tooltip
                                        title={params[key]}
                                        arrow
                                        placement="top"
                                      >
                                        <IconButton
                                          size="small"
                                          sx={{ ml: 1, p: 0 }}
                                        >
                                          <HelpCircle size={16} />
                                        </IconButton>
                                      </Tooltip>
                                    </Box>
                                  }
                                  type="number"
                                  value={value}
                                  onChange={(e) => {
                                    let inputVal = e.target.value;
                                    let newValue = inputVal === '' ? 0 : parseFloat(inputVal);
                                    newValue = Math.max(1e-5, Math.min(newValue, 1e-2)); // Ensure value is between 1e-5 and 1e-2
                                    handleAdvancedParamChange(key, newValue);
                                  }}
                                  InputProps={{
                                    inputProps: {
                                      step: 0.00001,
                                      min: 1e-5,
                                      max: 1e-2,
                                    }
                                  }}
                                  variant="outlined"
                                />
                              );
                            }

                            else if (key === "Lora Alpha") {
                              return (
                                <FormControl fullWidth>
                                  <InputLabel id={`${key}-label`}>
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                      {key}
                                      <Tooltip title={params[key]} arrow placement="top">
                                        <IconButton size="small" sx={{ ml: 1, p: 0 }}>
                                          <HelpCircle size={16} />
                                        </IconButton>
                                      </Tooltip>
                                    </Box>
                                  </InputLabel>
                                  <Select
                                    labelId={`${key}-label`}
                                    value={value || 1} 
                                    
                                    onChange={(e) => handleAdvancedParamChange(key, e.target.value)}
                                    label={key}
                                  >
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    
                                  </Select>
                                </FormControl>
                              );
                            }


                            else if (key === "Rank") {
                              return (
                                <FormControl fullWidth>
                                  <InputLabel id={`${key}-label`}>
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                      {key}
                                      <Tooltip title={params[key]} arrow placement="top">
                                        <IconButton size="small" sx={{ ml: 1, p: 0 }}>
                                          <HelpCircle size={16} />
                                        </IconButton>
                                      </Tooltip>
                                    </Box>
                                  </InputLabel>
                                  <Select
                                    labelId={`${key}-label`}
                                    value={value || 16} // Default value set to 16
                                    onChange={(e) => handleAdvancedParamChange(key, parseInt(e.target.value, 10))}
                                    label={key}
                                  >
                                    <MenuItem value={8}>8</MenuItem>
                                    <MenuItem value={16}>16</MenuItem>
                                    <MenuItem value={32}>32</MenuItem>
                                  </Select>
                                </FormControl>
                              );
                            }

                            else if (key === "Epochs") {
                              return (
                                <TextField
                                  fullWidth
                                  key={key}
                                  label={
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                      {key}
                                      <Tooltip title={params[key]} arrow placement="top">
                                        <IconButton size="small" sx={{ ml: 1, p: 0 }}>
                                          <HelpCircle size={16} />
                                        </IconButton>
                                      </Tooltip>
                                    </Box>
                                  }
                                  type="number"
                                  value={value || '1'} // Ensures default value is set to 1
                                  onChange={(e) => {
                                    handleAdvancedParamChange(key, e.target.value)
                                  }}
                                  InputProps={{
                                    inputProps: {
                                      step: 1,
                                      min: 1,
                                      max: 1000000000,
                                    }
                                  }}
                                  variant="outlined"
                                />
                              );

                            }
                            return (
                              <TextField
                                key={key}
                                label={
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {key}
                                    <Tooltip
                                      title={params[key]}
                                      arrow
                                      placement="top"
                                    >
                                      <IconButton
                                        size="small"
                                        sx={{ ml: 1, p: 0 }}
                                      >
                                        <HelpCircle size={16} />
                                      </IconButton>
                                    </Tooltip>
                                  </Box>
                                }
                                value={value}
                                onChange={(e) => {
                                  let newValue = e.target.value;

                                  if (isIntegerField) {
                                    newValue = e.target.value.replace(
                                      /[^0-9]/g,
                                      ""
                                    );
                                    if (newValue)
                                      newValue = parseInt(newValue, 10);
                                  }

                                  handleAdvancedParamChange(key, newValue);
                                }}
                                type={
                                  isIntegerField
                                    ? "number"
                                    : typeof value === "number"
                                      ? "number"
                                      : "text"
                                }
                                InputProps={{
                                  inputProps: {
                                    step: isIntegerField ? 1 : 0.1,
                                    min: 0,
                                  },
                                }}
                              />
                            );
                          }
                        )}
                        {/* <FormControl fullWidth>
                          <InputLabel id="quantization-method-label">
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              Quantization Method
                              <Tooltip
                                title={params["quantization_method"]}
                                arrow
                                placement="top"
                              >
                                <IconButton size="small" sx={{ ml: 1, p: 0 }}>
                                  <HelpCircle size={16} />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </InputLabel>
                          <Select
                            labelId="quantization-method-label"
                            value={formValues.advancedOptions.quantization_method}
                            onChange={(e) =>
                              handleAdvancedParamChange("quantization_method", e.target.value)
                            }
                            label="Quantization Method"
                          >
                            <MenuItem value="quantized">Quantized</MenuItem>
                            <MenuItem value="non-quantized">Non-Quantized</MenuItem>
                          </Select>
                        </FormControl> */}
                      </Box>
                    )}
                  </Box>
                </Box>
              )}

              {step === 4 && (
                <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                  <Typography variant="h6">Evaluation Management</Typography>
                  <FormControl fullWidth>
                    <InputLabel id="evaluation-dataset-type-label">
                      Evaluation Dataset
                    </InputLabel>
                    <Select
                      labelId="evaluation-dataset-type-label"
                      value={formValues.evaluationDatasetType}
                      onChange={(e) =>
                        handleInputChange(
                          "evaluationDatasetType",
                          e.target.value
                        )
                      }
                      label="Evaluation Dataset"
                    >
                      <MenuItem value="huggingface" disabled={["Summary", "Text Gen", "Sentiment Analysis"].includes(formValues.finetuneType)}>
                        Generate Evaluation Dataset
                      </MenuItem>
                      <MenuItem
                        value="use-training"
                        
                      >
                        Use Training-Test Split For Evaluation
                      </MenuItem>
                    </Select>
                  </FormControl>

                  {formValues.evaluationDatasetType === "huggingface" && (
                    <Box
                      sx={{
                        mt: 2,
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                      }}
                    >
                      <TextField
                        label="HF Dataset Name"
                        type="url"
                        value={formValues.Eval_HF_URL}
                        onChange={(e) => {
                          handleInputChange("Eval_HF_URL", e.target.value);
                        }}
                        placeholder="Eval HF URL"
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip title="Upload PDFs or Word documents to HuggingFace, provide the name of the dataset and we'll generate an evaluation dataset for you">
                                <IconButton>
                                  <HelpCircle size={20} />
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <TextField
                        label="Hugging Face API Key"
                        type={showHfApiKey ? "text" : "password"}
                        value={formValues.hfApiKey}
                        onChange={(e) =>
                          handleInputChange("hfApiKey", e.target.value)
                        }
                        placeholder="Enter your Hugging Face API key"
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle API key visibility"
                                onClick={() => setShowHfApiKey(!showHfApiKey)}
                                edge="end"
                              >
                                {showHfApiKey ? (
                                  <EyeOff size={20} />
                                ) : (
                                  <Eye size={20} />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <TextField
                        label="OpenAI API Key"
                        type={showApiKey ? "text" : "password"}
                        value={formValues.openAiApiKey}
                        onChange={(e) =>
                          handleInputChange("openAiApiKey", e.target.value)
                        }
                        placeholder="Enter your OpenAI API key"
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle API key visibility"
                                onClick={() => setShowApiKey(!showApiKey)}
                                edge="end"
                              >
                                {showApiKey ? (
                                  <EyeOff size={20} />
                                ) : (
                                  <Eye size={20} />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  )}

                  {formValues.evaluationDatasetType === "use-training" && (
                    <Box
                      sx={{
                        mt: 2,
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Switch
                            checked={formValues.generateEvaluationDataset}
                            onChange={(e) => {
                              const checked = e.target.checked;
                              handleInputChange("generateEvaluationDataset", checked);
                              if (checked) {
                                handleInputChange("Eval_HF_URL", formValues.hfDatasetUrl || formValues.selectedDataset);
                              } else {
                                handleInputChange("Eval_HF_URL", "");
                              }
                            }}
                            disabled={["Summary", "Text Gen", "Sentiment Analysis"].includes(formValues.finetuneType)}
                          />
                        }
                        label="Enhance with Synthetic Data"
                      />
                      {formValues.generateEvaluationDataset && (
                        <TextField
                          label="OpenAI API Key"
                          type={showApiKey ? "text" : "password"}
                          value={formValues.openAiApiKey}
                          onChange={(e) =>
                            handleInputChange("openAiApiKey", e.target.value)
                          }
                          placeholder="Enter your OpenAI API key"
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle API key visibility"
                                  onClick={() => setShowApiKey(!showApiKey)}
                                  edge="end"
                                >
                                  {showApiKey ? (
                                    <EyeOff size={20} />
                                  ) : (
                                    <Eye size={20} />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    </Box>
                  )}

                  <Card variant="outlined" sx={{ mt: 3, p: 2 }}>
                    <Typography variant="subtitle1" gutterBottom>
                      Standard Evaluation Metrics
                    </Typography>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formValues.evaluationMetrics.loss}
                          onChange={() => handleMetricChange("loss")}
                          disabled
                        />
                      }
                      label={
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          Loss
                          <Tooltip title={EvalTooltips.loss} arrow>
                            <IconButton size="small">
                              <HelpCircle size={16} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      }
                    />
                  </Card>

                  {!["Text Gen", "Sentiment Analysis"].includes(formValues.finetuneType) && (
                    <Card variant="outlined" sx={{ mt: 3, p: 2 }}>
                      <Typography variant="subtitle1" gutterBottom>
                        AI-Eval Configuration
                      </Typography>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={formValues.evaluationMetrics.aiEval}
                            onChange={() => handleMetricChange("aiEval")}
                          />
                        }
                        label="Enable AI-Eval"
                      />

                      {formValues.evaluationMetrics.aiEval && (
                        <Box sx={{ mt: 2 }}>
                          <TextField
                            label="OpenAI API Key"
                            type={formValues.showOpenAiApiKey ? "text" : "password"}
                            value={formValues.openAiApiKey}
                            onChange={(e) => handleInputChange("openAiApiKey", e.target.value)}
                            placeholder="Enter your OpenAI API key"
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle API key visibility"
                                    onClick={() =>
                                      handleInputChange(
                                        "showOpenAiApiKey",
                                        !formValues.showOpenAiApiKey
                                      )
                                    }
                                    edge="end"
                                  >
                                    {formValues.showOpenAiApiKey ? <EyeOff size={20} /> : <Eye size={20} />}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                          <Grid container spacing={2} sx={{ mt: 2 }}>
                            {Object.entries(formValues.aiEvalMetrics).map(([key, value]) => {
                              if (formValues.finetuneType === "QnA" && key === "summarisation") {
                                return null; // Do not render summarisation metric for QnA
                              }
                              const isDisabled = formValues.finetuneType === "Summary" && key !== "summarisation";
                              return (
                                <Grid item xs={6} key={key}>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={key === "summarisation" ? true : value} // Force Summarisation to be checked
                                        onChange={() => handleAiEvalMetricChange(key)}
                                        disabled={isDisabled}
                                      />
                                    }
                                    label={
                                      <Box sx={{ display: "flex", alignItems: "center" }}>
                                        {key === "geval" ? "G-Eval" : key.charAt(0).toUpperCase() + key.slice(1)}
                                        <Tooltip title={EvalTooltips[key]} arrow>
                                          <IconButton size="small">
                                            <HelpCircle size={16} />
                                          </IconButton>
                                        </Tooltip>
                                      </Box>
                                    }
                                  />
                                </Grid>
                              );
                            })}
                          </Grid>
                        </Box>
                      )}
                    </Card>
                  )}
                </Box>
              )}

              {step === 5 && (
                <Box>
                  <Typography variant="h6" gutterBottom>
                    Form Submission Summary
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Card variant="outlined" sx={{ height: "100%" }}>
                        <CardContent>
                          <Typography
                            variant="subtitle1"
                            gutterBottom
                            sx={{ fontWeight: "bold" }}
                          >
                            Fine-tuning Configuration
                          </Typography>
                          <List dense>
                            <ListItem>
                              <Typography variant="body2">
                                <strong>Type:</strong> {formValues.finetuneType}
                              </Typography>
                            </ListItem>
                            <ListItem>
                              <Typography variant="body2">
                                <strong>Data Source:</strong>{" "}
                                {formValues.dataSource}
                              </Typography>
                            </ListItem>
                            {formValues.selectedDataset && (
                              <ListItem>
                                <Typography variant="body2">
                                  <strong>Selected Dataset:</strong>{" "}
                                  {formValues.selectedDataset}
                                </Typography>
                              </ListItem>
                            )}
                            {formValues.hfDatasetUrl && (
                              <ListItem>
                                <Typography variant="body2">
                                  <strong>Hugging Face Dataset Name:</strong>{" "}
                                  {formValues.hfDatasetUrl}
                                </Typography>
                              </ListItem>
                            )}
                          </List>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Card variant="outlined" sx={{ height: "100%" }}>
                        <CardContent>
                          <Typography
                            variant="subtitle1"
                            gutterBottom
                            sx={{ fontWeight: "bold" }}
                          >
                            Training Configuration
                          </Typography>
                          <List dense>
                            <ListItem>
                              <Typography variant="body2">
                                <strong>Training Location:</strong>{" "}
                                {formValues.trainingLocation}
                              </Typography>
                            </ListItem>
                            {formValues.trainingLocation === "local" && (
                              <ListItem>
                                <Typography variant="body2">
                                  <strong>Optimize for Silicon:</strong>{" "}
                                  {formValues.optimizeForSilicon ? "Yes" : "No"}
                                </Typography>
                              </ListItem>
                            )}
                            <ListItem>
                              <Typography variant="body2">
                                <strong>Model Saving:</strong>{" "}
                                {formValues.modelSaving}
                              </Typography>
                            </ListItem>
                            {["save-hf", "convert-gguf-hf"].includes(
                              formValues.modelSaving
                            ) && (
                              <ListItem>
                                <Typography variant="body2">
                                  <strong>HF Repository Name:</strong>{" "}
                                  {formValues.hfRepoName}
                                </Typography>
                              </ListItem>
                            )}
                          </List>
                        </CardContent>
                      </Card>
                    </Grid>
                    {Object.entries(formValues.advancedOptions).some(
                      ([key, value]) => value !== defaultAdvancedOptions[key]
                    ) && (
                      <Grid item xs={12}>
                        <Card variant="outlined">
                          <CardContent>
                            <Typography
                              variant="subtitle1"
                              gutterBottom
                              sx={{ fontWeight: "bold" }}
                            >
                              Advanced Parameters
                            </Typography>
                            <Grid container spacing={2}>
                              {Object.entries(formValues.advancedOptions).map(
                                ([key, value]) => {
                                  if (value !== defaultAdvancedOptions[key]) {
                                    return (
                                      <Grid item xs={6} key={key}>
                                        <Typography variant="body2">
                                          <Box
                                            component="span"
                                            sx={{ fontWeight: "bold" }}
                                          >
                                            {key}:
                                          </Box>{" "}
                                          {value}
                                        </Typography>
                                      </Grid>
                                    );
                                  }
                                  return null;
                                }
                              )}
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Card variant="outlined">
                        <CardContent>
                          <Typography
                            variant="subtitle1"
                            gutterBottom
                            sx={{ fontWeight: "bold" }}
                          >
                            Evaluation Configuration
                          </Typography>
                          <List dense>
                            {formValues.evaluationDatasetType === "huggingface" && (
                              <ListItem>
                                <Typography variant="body2">
                                  <strong>Evaluation Dataset:</strong>{" "}
                                  {formValues.Eval_HF_URL}
                                </Typography>
                              </ListItem>
                            )}
                            {formValues.evaluationDatasetType === "use-training" && (
                              <ListItem>
                                <Typography variant="body2">
                                  <strong>Evaluation Dataset:</strong>{" "}
                                  {formValues.Eval_HF_URL}
                                </Typography>
                              </ListItem>
                            )}
                            <ListItem>
                              <Typography variant="body2">
                                <strong>Standard Evaluation Metrics:</strong>{" "}
                                {Object.entries(formValues.evaluationMetrics)
                                  .filter(([key, value]) => value && key !== "aiEval")
                                  .map(([key]) => key)
                                  .join(", ")}
                              </Typography>
                            </ListItem>
                            {formValues.evaluationMetrics.aiEval && (
                              <ListItem>
                                <Typography variant="body2">
                                  <strong>AI Evaluation Metrics:</strong>{" "}
                                  {Object.entries(formValues.aiEvalMetrics)
                                    .filter(([key, value]) => value)
                                    .map(([key]) => key)
                                    .join(", ")}
                                </Typography>
                              </ListItem>
                            )}
                          </List>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>

                  <Box sx={{ mt: 4, mb: 2 }}>
                    <Card variant="outlined" sx={{ p: 2 }}>
                      <CardContent>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          sx={{ fontWeight: "bold" }}
                        >
                          Name Your Creation 🚀
                        </Typography>
                        <TextField
                          fullWidth
                          value={formValues.creationName}
                          onChange={(e) =>
                            handleInputChange("creationName", e.target.value)
                          }
                          variant="outlined"
                          size="small"
                          sx={{
                            mt: 1,
                            mb: 1,
                            width: "100%",
                          }}
                        />
                      </CardContent>
                    </Card>
                  </Box>
                </Box>
              )}
            </CardContent>
            <CardActions
              sx={{ justifyContent: "space-between", p: 3, display: "flex" }}
            >
              <Button
                variant="outlined"
                onClick={() => handleInputChange("step", formValues.step - 1)}
                disabled={formValues.step === 1}
                startIcon={<ChevronLeft size={20} />}
                sx={{
                  borderRadius: "8px",
                  textTransform: "uppercase",
                  fontWeight: 500,
                  color: "rgb(100, 116, 139)",
                  borderColor: "rgb(226, 232, 240)",
                  "&:hover": {
                    borderColor: "rgb(203, 213, 225)",
                  },
                }}
              >
                Previous
              </Button>

              <Button
                variant="contained"
                onClick={() => {
                  if (formValues.step === 5) {
                    submitFormHandler(formValues);
                    navigate("/finetuning-dashboard");
                  } else {
                    handleNextStep();
                  }
                }}
                endIcon={
                  formValues.step === 5 ? <ChevronRight size={20} /> : null
                }
                sx={{
                  borderRadius: "8px",
                  textTransform: "uppercase",
                  fontWeight: 500,
                  bgcolor: formValues.step === 5 ? "#22c55e" : "rgb(59,89,238)",
                  "&:hover": {
                    bgcolor:
                      formValues.step === 5 ? "#16a34a" : "rgb(48,73,191)",
                  },
                }}
              >
                {formValues.step === 5 ? "Confirm Submission" : "Next"}
              </Button>
            </CardActions>
          </Card>
        </Box>
      </Box>
    </FormProvider>
  );
}
