/**
 * PerformanceGraphs.js
 * A component that visualizes performance metrics using charts and graphs.
 * Shows score progression and loss metrics over time, with visual indicators
 * for improvements and regressions.
 */

import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  AreaChart,
  Area,
  LineChart,
  Line,
} from "recharts";

const PerformanceGraphs = ({ runData }) => {
  // Prevent event propagation for chart interactions
  const handleClick = (e) => {
    e.stopPropagation();
  };

  // Check if score and loss metrics are available
  const hasScoreMetrics = runData.average_old_AI_Evals  !== null && runData.average_new_AI_Evals !== null;
  const hasLossMetrics = runData.trainLoss !== null && runData.testLoss !== null;

  // Show message if no metrics are available for visualization
  if (!hasScoreMetrics && !hasLossMetrics) {
    return (
      <div className="mt-4 w-full" onClick={handleClick}>
        <h3 className="text-lg font-semibold text-[#3B4B8C] mb-2">
          Model Performance Analysis
        </h3>
        <p className="text-gray-600">
          No visualization data available for this run.
        </p>
      </div>
    );
  }

  // Prepare score progression data with intermediate point for smooth visualization
  const scoreData = hasScoreMetrics
    ? [
        {
          name: "Original",
          score: parseFloat(runData.average_old_AI_Evals),
          timestamp: 0,
        },

        {
          name: "Fine-tuned",
          score: parseFloat(runData.average_new_AI_Evals),
          timestamp: 100,
        },
      ]
    : [];

  // Prepare loss metrics data with intermediate point for smooth visualization
  const lossData = hasLossMetrics
    ? [
        {
          name: "Training",
          loss: parseFloat(runData.trainLoss),
          timestamp: 0,
        },

        {
          name: "Test",
          loss: parseFloat(runData.testLoss),
          timestamp: 100,
        },
      ]
    : [];

  // Determine if loss is improving (decreasing)
  const isLossImproving = hasLossMetrics && runData.testLoss < runData.trainLoss;

  return (
    <div className="mt-4 w-full" onClick={handleClick}>
      <h3 className="text-lg font-semibold text-[#3B4B8C] mb-2">
        Model Performance Analysis
      </h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {/* Score Progression Chart */}
        {hasScoreMetrics && (
          <div className="bg-white p-4 rounded-lg shadow">
            <h4 className="text-sm font-semibold mb-2">
              Model Score Progression - Compare Original vs Fine-tuned (Only If AI Metrics Enabled)
            </h4>
            <ResponsiveContainer width="100%" height={300}>
              <AreaChart data={scoreData}>
                {/* Gradient definition for chart fill */}
                <defs>
                  <linearGradient
                    id="scoreGradient"
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                  >
                    <stop 
                      offset="5%" 
                      stopColor={runData.average_new_AI_Evals > runData.average_old_AI_Evals ? "#4ade80" : "#ef4444"} 
                      stopOpacity={0.8} 
                    />
                    <stop 
                      offset="95%" 
                      stopColor={runData.average_new_AI_Evals > runData.average_old_AI_Evals ? "#4ade80" : "#ef4444"} 
                      stopOpacity={0.1} 
                    />
                  </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="timestamp"
                  domain={[0, 100]}
                  ticks={[0, 100]}
                  tickFormatter={(value) =>
                    value === 0 ? "Original" : "Fine-tuned"
                  }
                />
                <YAxis domain={[0, 1]} />
                <Tooltip
                  formatter={(value) => value.toFixed(2)}
                  labelFormatter={(value) =>
                    value === 0
                      ? "Original"
                      : value === 100
                        ? "Fine-tuned"
                        : "Progress"
                  }
                />
                <Area
                  type="monotone"
                  dataKey="score"
                  stroke={runData.average_new_AI_Evals > runData.average_old_AI_Evals ? "#4ade80" : "#ef4444"}
                  fill="url(#scoreGradient)"
                />
              </AreaChart>
            </ResponsiveContainer>
            {/* Score improvement percentage */}
            <div className="text-center mt-2 text-sm text-gray-600">
              {`Improvement: ${(((runData.average_new_AI_Evals - runData.average_old_AI_Evals) / runData.average_old_AI_Evals) * 100).toFixed(1)}%`}
            </div>
          </div>
        )}

        {/* Loss Metrics Chart */}
        {hasLossMetrics && (
          <div className="bg-white p-4 rounded-lg shadow">
            <h4 className="text-sm font-semibold mb-2">
              Loss Metrics Comparison
            </h4>
            <ResponsiveContainer width="100%" height={300}>
              <AreaChart data={lossData}>
                {/* Gradient definition for chart fill */}
                <defs>
                  <linearGradient
                    id="lossGradient"
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                  >
                    <stop 
                      offset="5%" 
                      stopColor={isLossImproving ? "#4ade80" : "#ef4444"} 
                      stopOpacity={0.8} 
                    />
                    <stop 
                      offset="95%" 
                      stopColor={isLossImproving ? "#4ade80" : "#ef4444"} 
                      stopOpacity={0.1} 
                    />
                  </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="timestamp"
                  domain={[0, 100]}
                  ticks={[0, 100]}
                  tickFormatter={(value) =>
                    value === 0 ? "Training" : "Test"
                  }
                />
                <YAxis />
                <Tooltip
                  formatter={(value) => value.toFixed(2)}
                  labelFormatter={(value) =>
                    value === 0
                      ? "Training"
                      : value === 100
                        ? "Test"
                        : "Progress"
                  }
                />
                <Area
                  type="monotone"
                  dataKey="loss"
                  stroke={isLossImproving ? "#4ade80" : "#ef4444"}
                  fill="url(#lossGradient)"
                />
              </AreaChart>
            </ResponsiveContainer>
            {/* Loss difference percentage */}
            <div className="text-center mt-2 text-sm text-gray-600">
              {`Train vs Test Loss: ${(((runData.testLoss - runData.trainLoss) / runData.trainLoss) * 100).toFixed(2)}%`}
            </div>
          </div>
        )}

        {/* Key Performance Indicators Section */}
        <div className="md:col-span-2 bg-white p-4 rounded-lg shadow">
          <h4 className="text-sm font-semibold mb-4">
            Key Performance Indicators
          </h4>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            {/* Score Metrics */}
            {hasScoreMetrics && (
              <>
                {/* Fine-tuned Score */}
                <div className="text-center p-3 bg-green-50 rounded-lg group relative">
                  <div className="text-sm text-gray-600">Fine-tuned Score (Only If AI Metrics Enabled)</div>
                  <div className="text-xl font-bold text-green-600">
                    {runData.average_new_AI_Evals.toFixed(2)}
                  </div>
                  {/* Tooltip */}
                  <div className="opacity-0 group-hover:opacity-100 transition-opacity absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-2 bg-gray-900 text-white text-xs rounded-lg w-48 pointer-events-none">
                    The average performance score of your fine-tuned model. Higher scores indicate better performance.
                    <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 rotate-45 w-2 h-2 bg-gray-900"></div>
                  </div>
                </div>
                {/* Original Score */}
                <div className="text-center p-3 bg-blue-50 rounded-lg group relative">
                  <div className="text-sm text-gray-600">Original Score (Only If AI Metrics Enabled)</div>
                  <div className="text-xl font-bold text-blue-600">
                    {runData.average_old_AI_Evals.toFixed(2)}
                  </div>
                  {/* Tooltip */}
                  <div className="opacity-0 group-hover:opacity-100 transition-opacity absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-2 bg-gray-900 text-white text-xs rounded-lg w-48 pointer-events-none">
                    The average performance score of the original model before fine-tuning. Used as a baseline for comparison.
                    <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 rotate-45 w-2 h-2 bg-gray-900"></div>
                  </div>
                </div>
              </>
            )}
            {/* Loss Metrics */}
            {hasLossMetrics && (
              <>
                {/* Training Loss */}
                <div className="text-center p-3 bg-orange-50 rounded-lg group relative">
                  <div className="text-sm text-gray-600">Training Loss</div>
                  <div className="text-xl font-bold text-orange-600">
                    {runData.trainLoss.toFixed(2)}
                  </div>
                  {/* Tooltip */}
                  <div className="opacity-0 group-hover:opacity-100 transition-opacity absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-2 bg-gray-900 text-white text-xs rounded-lg w-48 pointer-events-none">
                    Measures how well the model is learning from training data. Lower values indicate better learning performance.
                    <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 rotate-45 w-2 h-2 bg-gray-900"></div>
                  </div>
                </div>
                {/* Test Loss */}
                <div className="text-center p-3 bg-purple-50 rounded-lg group relative">
                  <div className="text-sm text-gray-600">Test Loss</div>
                  <div className="text-xl font-bold text-purple-600">
                    {runData.testLoss.toFixed(2)}
                  </div>
                  {/* Tooltip */}
                  <div className="opacity-0 group-hover:opacity-100 transition-opacity absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-2 bg-gray-900 text-white text-xs rounded-lg w-48 pointer-events-none">
                    Measures model performance on unseen data. Should be close to training loss - if much higher, may indicate overfitting.
                    <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 rotate-45 w-2 h-2 bg-gray-900"></div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PerformanceGraphs; 