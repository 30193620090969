'use client';

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  signInWithPopup,
  GoogleAuthProvider,
} from 'firebase/auth';
import { auth, db } from '../firebase/config'; // Import db for database interactions
import { doc, getDoc, setDoc } from 'firebase/firestore'; // Firestore functions for checking user login

const googleProvider = new GoogleAuthProvider();

export default function LoginPage() {
  const navigate = useNavigate();
  const [error, setError] = useState('');

  const handleGoogleSignIn = async () => {
    setError('');
    try {
      const result = await signInWithPopup(auth, googleProvider);
      console.log('Signed in with Google:', result.user);
      const isFirstLogin = await checkIfFirstLogin(result.user.uid);
      if (isFirstLogin) {
        console.log('Navigating to /finetuning-form');
        navigate('/finetuning-form');
      } else {
        console.log('Navigating to /finetuning-dashboard');
        navigate('/finetuning-dashboard');  
      }
    } catch (error) {
      setError('Failed to sign in with Google. Please try again.');
      console.error('Error signing in with Google:', error);
    }
  };

  const checkIfFirstLogin = async (userId) => {
    const userDoc = doc(db, 'users', userId);
    try {
      const docSnap = await getDoc(userDoc);
      if (docSnap.exists()) {
        return false; // User has logged in before
      } else {
        await setDoc(userDoc, { firstLogin: false });
        return true; // First login
      }
    } catch (error) {
      console.error('Error fetching user document:', error);
      setError('Error fetching user document. Please try again later.');
      return false;
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-[#F5F7FF]">
      <div className="w-full max-w-md p-8 space-y-8 bg-white rounded-lg shadow-sm">
        <div className="text-center">
          <h1 className="text-3xl font-bold text-blue-600">Tune</h1>
          <p className="mt-2 text-gray-600">Sign in to your account</p>
        </div>
        {error && (
          <div className="p-3 text-sm text-red-500 bg-red-100 border border-red-200 rounded">
            {error}
          </div>
        )}
        <div className="flex justify-center">
          <button
            onClick={handleGoogleSignIn}
            className="flex items-center justify-center w-full py-2 px-4 bg-white hover:bg-gray-50 text-gray-700 font-semibold rounded-md border border-gray-300 transition duration-200"
          >
            <svg className="w-5 h-5 mr-2" viewBox="0 0 24 24">
              {/* Google logo SVG path */}
              <path
                fill="#4285F4"
                d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
              />
              <path
                fill="#34A853"
                d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
              />
              <path
                fill="#FBBC05"
                d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
              />
              <path
                fill="#EA4335"
                d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
              />
            </svg>
            Continue with Google
          </button>
        </div>
      </div>
    </div>
  );
}
