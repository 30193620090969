/**
 * EvaluationTabs.js
 * Component that handles the display of various evaluation metrics and results
 * for a fine-tuned model. Includes tabs for different types of evaluations such as
 * G-Eval, Answer Relevancy, Toxicity, Tool Correctness, and Bias.
 */

import React from 'react';
import { Eye, EyeOff } from 'lucide-react';
import MetricsTable from './MetricsTable';
import PerformanceGraphs from './PerformanceGraphs';
import ResponseTable from './ResponseTable';

const EvaluationTabs = ({ 
  activeEvalTab,          // Currently active evaluation tab
  setActiveEvalTab,       // Function to change active tab
  runData,                // Data for the current fine-tuning run
  showTokens,             // Whether to show sensitive tokens
  setShowTokens           // Function to toggle token visibility
}) => {
  // Helper function to check if a specific evaluation type exists in the data
  const hasEvalType = (type) => {
    return runData[0]?.AI_Eval?.[type]?.best?.length > 0 || 
           runData[0]?.AI_Eval?.[type]?.worst?.length > 0;
  };

  // Check for available metrics in the run data
  const hasMetrics = runData[0]?.average_new !== null || 
                    runData[0]?.average_old !== null || 
                    runData[0]?.trainLoss !== null || 
                    runData[0]?.testLoss !== null;

  // Check specifically for G-Eval data (handles both casing variants)
  const hasGEval = runData[0]?.AI_Eval?.Geval || runData[0]?.AI_Eval?.GEval;

  return (
    <div>
      {/* Beta Notice Banner - Informs users about the beta status of evaluation features */}
      <div className="mb-6 p-4 bg-yellow-50 border border-yellow-100 rounded-lg">
        <div className="flex items-start">
          <div className="flex-shrink-0">
            <svg className="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
            </svg>
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-yellow-800">Evaluation Feature in Beta</h3>
            <div className="mt-2 text-sm text-yellow-700">
              <p>This evaluation feature is currently in beta. We're working on improving the accuracy and display of evaluation metrics.</p>
            </div>
          </div>
        </div>
      </div>

      {/* Evaluation Tabs Navigation - Only shown if evaluation data exists */}
      {(runData[0].AI_Eval || runData[0].average_new !== null) && (
        <div className="mb-6">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8">
              {/* Overview Tab - Shows general performance metrics */}
              {hasMetrics && (
                <button
                  onClick={() => setActiveEvalTab("Overview")}
                  className={`
                    whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm
                    ${activeEvalTab === "Overview"
                      ? 'border-blue-500 text-blue-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                    }
                  `}
                >
                  Overview
                </button>
              )}

              {/* G-Eval Tab - Shows general evaluation metrics */}
              {hasGEval && (
                <button
                  onClick={() => setActiveEvalTab("G-Eval")}
                  className={`
                    whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm
                    ${activeEvalTab === "G-Eval"
                      ? 'border-blue-500 text-blue-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                    }
                  `}
                >
                  G-Eval
                </button>
              )}

              {runData[0].AI_Eval?.Bias && (
                <button
                  onClick={() => setActiveEvalTab("Bias")}
                  className={`
                    whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm
                    ${activeEvalTab === "Bias"
                      ? 'border-blue-500 text-blue-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                    }
                  `}
                >
                  Bias
                </button>
              )}

              {hasEvalType('Toxicity') && (
                <button
                  onClick={() => setActiveEvalTab("Toxicity")}
                  className={`
                    whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm
                    ${activeEvalTab === "Toxicity"
                      ? 'border-blue-500 text-blue-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                    }
                  `}
                >
                  Toxicity
                </button>
              )}

              {hasEvalType('Relevancy') && (
                <button
                  onClick={() => setActiveEvalTab("Answer Relevancy")}
                  className={`
                    whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm
                    ${activeEvalTab === "Answer Relevancy"
                      ? 'border-blue-500 text-blue-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                    }
                  `}
                >
                  Answer Relevancy
                </button>
              )}

              {hasEvalType('ToolCorrectness') && (
                <button
                  onClick={() => setActiveEvalTab("Tool Correctness")}
                  className={`
                    whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm
                    ${activeEvalTab === "Tool Correctness"
                      ? 'border-blue-500 text-blue-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                    }
                  `}
                >
                  Tool Correctness
                </button>
              )}

              {hasEvalType('Summary') && (
                <button
                  onClick={() => setActiveEvalTab("Summary")}
                  className={`
                    whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm
                    ${activeEvalTab === "Summary"
                      ? 'border-blue-500 text-blue-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                    }
                  `}
                >
                  Summary
                </button>
              )}
            </nav>
          </div>
        </div>
      )}

      {/* Tab Contents - Renders different content based on active tab */}
      {runData[0].AI_Eval || runData[0].average_new !== null ? (
        <>
          {/* Overview Tab Content - Shows performance graphs */}
          {activeEvalTab === "Overview" && hasMetrics && (
            <PerformanceGraphs runData={runData[0]} />
          )}

          {/* G-Eval Tab Content - Shows best and worst examples */}
          {activeEvalTab === "G-Eval" && (
            <div className="space-y-8">
              {hasGEval && (
                <>
                  {/* Display top performing examples */}
                  <ResponseTable
                    data={[
                      ...(runData[0].AI_Eval?.Geval?.best || []),
                      ...(runData[0].AI_Eval?.GEval?.best || [])
                    ]}
                    title="Top G-Eval Examples"
                  />
                  {/* Display examples needing improvement */}
                  <ResponseTable
                    data={[
                      ...(runData[0].AI_Eval?.Geval?.worst || []),
                      ...(runData[0].AI_Eval?.GEval?.worst || [])
                    ]}
                    title="Areas for G-Eval Improvement"
                  />
                </>
              )}
            </div>
          )}

          {/* Answer Relevancy Tab Content - Shows relevancy evaluation results */}
          {activeEvalTab === "Answer Relevancy" && (
            <div className="space-y-8">
              {hasEvalType('Relevancy') && (
                <>
                  <ResponseTable
                    data={runData[0].AI_Eval?.Relevancy?.best || []}
                    title="Top Relevancy Examples"
                  />
                  <ResponseTable
                    data={runData[0].AI_Eval?.Relevancy?.worst || []}
                    title="Areas for Relevancy Improvement"
                  />
                </>
              )}
            </div>
          )}

          {/* Toxicity Tab Content - Shows toxicity evaluation results */}
          {activeEvalTab === "Toxicity" && (
            <div className="space-y-8">
              {hasEvalType('Toxicity') && (
                <>
                  <ResponseTable
                    data={runData[0].AI_Eval?.Toxicity?.best || []}
                    title="Least Toxic Examples"
                  />
                  <ResponseTable
                    data={runData[0].AI_Eval?.Toxicity?.worst || []}
                    title="Most Toxic Examples"
                  />
                </>
              )}
            </div>
          )}

          {/* Tool Correctness Tab Content - Shows tool usage evaluation results */}
          {activeEvalTab === "Tool Correctness" && (
            <div className="space-y-8">
              {hasEvalType('ToolCorrectness') && (
                <>
                  <ResponseTable
                    data={runData[0].AI_Eval?.ToolCorrectness?.best || []}
                    title="Best Tool Usage Examples"
                  />
                  <ResponseTable
                    data={runData[0].AI_Eval?.ToolCorrectness?.worst || []}
                    title="Tool Usage Issues"
                  />
                </>
              )}
            </div>
          )}

          {/* Bias Tab Content - Shows bias evaluation results */}
          {activeEvalTab === "Bias" && (
            <div className="space-y-8">
              {runData[0].AI_Eval?.Bias && (
                <>
                  <ResponseTable
                    data={runData[0].AI_Eval?.Bias?.best || []}
                    title="Least Biased Examples"
                  />
                  <ResponseTable
                    data={runData[0].AI_Eval?.Bias?.worst || []}
                    title="Most Biased Examples"
                  />
                </>
              )}
            </div>
          )}


          {/* Summary Tab Content - Shows summary evaluation results */}
          {activeEvalTab === "Summary" && (
            <div className="space-y-8">
              {runData[0].AI_Eval?.Summary && (
                <>
                  <ResponseTable
                    data={runData[0].AI_Eval?.Summary?.best || []}
                    title="Summary"
                  />
                  <ResponseTable
                    data={runData[0].AI_Eval?.Summary?.worst || []}
                    title="Summary"
                  />
                </>
              )}
            </div>
          )}
        </>
      ) : null}
    </div>
  );
};

export default EvaluationTabs; 