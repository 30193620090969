import { Link } from 'react-router-dom';

export default function NotFound() {
  return (
    <div className="min-h-screen flex items-center justify-center bg-[#E6EEFF]">
      <div className="w-full max-w-md p-8 bg-white rounded-lg shadow-lg border border-[#3B4B8C] text-center">
        <h1 className="text-7xl font-bold text-blue-600 mb-4">404</h1>
        <h2 className="text-2xl font-semibold text-[#3B4B8C] mb-4">
          Oops! Page Not Found
        </h2>
        <p className="text-[#3D4852] mb-8">
          The page you are looking for doesn&apos;t exist or has been moved.
        </p>
        <Link
          to="/login"
          className="inline-flex px-6 py-3 text-sm font-semibold text-white bg-blue-600 rounded-md hover:bg-blue-700 transition-colors"
        >
          Back to Login Page
        </Link>
      </div>
    </div>
  );
}
