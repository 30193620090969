import React from 'react';
import ReactMarkdown from 'react-markdown';

// Improved helper to safely get numeric values
const getNumericValue = (value) => {
  if (value === undefined || value === null) return null;
  const num = typeof value === 'string' ? parseFloat(value) : value;
  return isFinite(num) ? num : null;
};

// Improved markdown detection
const containsMarkdown = (text) => {
  if (!text || typeof text !== 'string') return false;
  const markdownPatterns = [
    /[*_]{1,2}[^*_]+[*_]{1,2}/,
    /#{1,6}\s/,
    /`[^`]+`/,
    /\[[^\]]+\]\([^)]+\)/,
    /```[\s\S]*?```/,
    /^\s*[-*+]\s/,
    /^\s*\d+\.\s/
  ];
  return markdownPatterns.some(pattern => pattern.test(text));
};

// Improved truncation with null safety
const truncateText = (text, maxLength) => {
  if (!text || typeof text !== 'string') return '';
  return text.length <= maxLength ? text : `${text.substr(0, maxLength)}...`;
};

// Improved improvement calculation
const calcImprovement_best_worst = (newScore, oldScore) => {
  const newScoreNum = getNumericValue(newScore);
  const oldScoreNum = getNumericValue(oldScore);
  
  if (newScoreNum === null || oldScoreNum === null) return null;
  
  // Special case: both scores are 0
  if (oldScoreNum === 0 && newScoreNum === 0) return 0;
  
  // Special case: improvement from 0
  if (oldScoreNum === 0) {
    return newScoreNum > 0 ? 100 : -100;  // Consider any positive number an improvement
  }
  
  // Special case: regression to 0
  if (newScoreNum === 0) {
    return -100;  // Always consider dropping to 0 as a complete regression
  }
  
  // Handle negative scores
  if (oldScoreNum < 0 && newScoreNum < 0) {
    // For negative scores, improvement is when the number gets closer to 0
    return ((Math.abs(oldScoreNum) - Math.abs(newScoreNum)) / Math.abs(oldScoreNum)) * 100;
  }
  
  return ((newScoreNum - oldScoreNum) / Math.abs(oldScoreNum)) * 100;
};

// Safe text renderer
const RenderText = ({ text }) => {
  if (!text || typeof text !== 'string') return null;
  
  if (containsMarkdown(text)) {
    return (
      <ReactMarkdown 
        className="prose prose-sm max-w-none"
        components={{
          code: ({node, inline, className, children, ...props}) => (
            <code className={`${className} px-1 py-0.5 bg-gray-100 rounded`} {...props}>
              {children}
            </code>
          ),
        }}
      >
        {text}
      </ReactMarkdown>
    );
  }
  
  return <span>{text}</span>;
};

// Safe score display component
const ScoreDisplay = ({ score }) => {
  const numericScore = getNumericValue(score);
  
  if (numericScore === null) {
    return <span className="text-sm text-gray-500">N/A</span>;
  }
  
  return (
    <span className="text-sm font-medium text-gray-900">
      {numericScore.toFixed(2)}
    </span>
  );
};

// Improved ResponseTable component
const ResponseTable = ({ data = [], title = 'Results' }) => {
  if (!Array.isArray(data) || data.length === 0) return null;

  return (
    <div className="mt-6 w-full bg-white rounded-lg shadow-sm">
      <div className="px-6 py-4 border-b border-gray-100">
        <div className="flex justify-between items-center">
          <h3 className="text-lg font-medium text-gray-900">{title}</h3>
        </div>
      </div>
      
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr className="bg-gray-50 text-left">
              <th className="px-6 py-3 text-xs font-medium text-gray-500">Input & Response</th>
              <th className="px-6 py-3 text-xs font-medium text-gray-500 text-center w-24">Original Score</th>
              <th className="px-6 py-3 text-xs font-medium text-gray-500 text-center w-24">Tuned Score</th>
              <th className="px-6 py-3 text-xs font-medium text-gray-500 text-center w-24">Change</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-100">
            {data.map((item, index) => {
              // Safely access scores with explicit undefined checks
              const originalScore = item?.score_old !== undefined ? item.score_old : item?.eval_old;
              const tunedScore = item?.score_new !== undefined ? item.score_new : item?.eval_new;
              const improvement_best_worst = calcImprovement_best_worst(tunedScore, originalScore);

              return (
                <tr key={index} className="hover:bg-gray-50">
                  <td className="px-6 py-4">
                    <details className="text-sm">
                      <summary className="cursor-pointer text-gray-900 font-medium mb-2">
                        <RenderText text={truncateText(item?.input ?? "No input", 100)} />
                      </summary>
                      <div className="mt-3 space-y-3 pl-4 border-l-2 border-gray-100">
                        <details className="text-m">
                          <summary className="font-medium text-black mb-1 cursor-pointer">
                            <strong>Expected Output</strong>
                          </summary>
                          <div className="mt-3 space-y-3 pl-4 border-l-2 border-gray-100">
                            <div className="text-sm text-gray-900">
                              <RenderText text={item?.expected_output ?? "None provided"} />
                            </div>
                          </div>
                        </details>

                        <details className="text-m">
                          <summary className="font-medium text-black mb-1 cursor-pointer">
                            <strong>Output from Fine-Tuned Model</strong>
                          </summary>
                          <div className="mt-3 space-y-3 pl-4 border-l-2 border-gray-100">
                            <div className="text-sm text-gray-900">
                              <RenderText text={item?.response_new ?? "No response"} />
                            </div>
                            <div className="text-xs text-gray-500 mt-1 bg-gray-100 p-2 border border-gray-300">
                              <strong>
                                <RenderText text={`AI-Assessment: ${item?.reason_new ?? item?.eval_new_reason ?? "No assessment"}`} />
                              </strong>
                            </div>
                          </div>
                        </details>

                        <details className="text-m">
                          <summary className="font-medium text-black mb-1 cursor-pointer">
                            <strong>Output from Base Model</strong>
                          </summary>
                          <div className="text-sm text-gray-900">
                            <RenderText text={item?.response_old ?? "No response"} />
                          </div>
                          <div className="text-xs text-gray-500 mt-1 bg-gray-100 p-2 border border-gray-300">
                            <strong>
                              <RenderText text={`AI-Assessment: ${item?.reason_old ?? item?.eval_old_reason ?? "No assessment"}`} />
                            </strong>
                          </div>
                        </details>
                      </div>
                    </details>
                  </td>
                  
                  <td className="px-6 py-4 text-center">
                    <ScoreDisplay score={originalScore} />
                  </td>
                  <td className="px-6 py-4 text-center">
                    <ScoreDisplay score={tunedScore} />
                  </td>
                  <td className="px-6 py-4 text-center">
                    {improvement_best_worst !== null ? (
                      <span className={`text-xs font-medium ${
                        improvement_best_worst >= 0 ? 'text-green-600' : 'text-red-600'
                      }`}>
                        {improvement_best_worst >= 0 ? '↑' : '↓'} {Math.abs(improvement_best_worst).toFixed(2)}%
                      </span>
                    ) : (
                      <span className="text-xs text-gray-500">N/A</span>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ResponseTable;