import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import LandingPage from './components/LandingPage';
import LoginPage from './components/Login';
import FineTuningForm from './components/FineTuningForm';
import FineTuningDashboard from './components/FinetuningDashboard';
import { ProtectedRoute } from './components/ProtectedRoute';
import NotFound from './components/NotFound';
import Resources from './components/Resources';
const App = () => {
  console.log("App rendering");
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/resources" element={<Resources />} />
          <Route path="/finetune-form" element={
            <ProtectedRoute>
              <FineTuningForm />
            </ProtectedRoute>
          } />
          <Route path="/finetuning-dashboard" element={
            <ProtectedRoute>
              <FineTuningDashboard />
            </ProtectedRoute>
          } />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
