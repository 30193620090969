/**
 * FinetuningDashboard.js
 * Main dashboard component that serves as the central hub for the fine-tuning application.
 * Manages the overall layout and state of the application, including profile settings,
 * datasets, and fine-tuning runs.
 */

"use client";

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./ui/Header";
import { db } from '../firebase/config';
import { doc, getDoc } from "firebase/firestore";
import { getAuth } from 'firebase/auth';
import ProfileSection from "./dashboard/ProfileSection";
import DatasetsSection from "./dashboard/DatasetsSection";
import RunCard from "./dashboard/RunCard";
import { fetchCompletedRunsData } from "../utils/firebaseUtils";
import { FolderPlus } from "lucide-react";
// Define sidebar navigation items
const sidebarItems = [
  { name: "Tune Runs", icon: "Hammer" },
  { name: "My Datasets", icon: "Database", disabled: true, comingSoon: true },
  { name: "Profile", icon: "User" },
];

export default function FinetuningDashboard() {
  // State management for various dashboard features
  const [activeItem, setActiveItem] = useState('Tune Runs');  // Currently selected sidebar item
  const [expandedCard, setExpandedCard] = useState(null);     // ID of the currently expanded run card
  const [completedRunsData, setCompletedRunsData] = useState({}); // Data for completed fine-tuning runs
  const [activeTab, setActiveTab] = useState('details');      // Active tab within a run card
  const [activeEvalTab, setActiveEvalTab] = useState('Overview'); // Active evaluation tab
  const [isLoading, setIsLoading] = useState(true);          // Loading state indicator
  const [error, setError] = useState(null);                  // Error state management
  
  // Profile data state management
  const [profileData, setProfileData] = useState({
    email: '',
    huggingFaceToken: '',
    modalSecret: '',
    modalToken: '',
  });
  
  const navigate = useNavigate();

  // Effect hook to fetch user data and completed runs on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const auth = getAuth();
        const user = auth.currentUser;

        if (user) {
          // Fetch user's profile data from Firestore
          const userDocRef = doc(db, 'users', user.uid);
          const userDoc = await getDoc(userDocRef);
          
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setProfileData({
              email: user.email || '',
              huggingFaceToken: userData.huggingFaceToken || '',
              modalSecret: userData.modalSecret || '',
              modalToken: userData.modalToken || '',
            });
          }

          // Fetch and set completed fine-tuning runs data
          const data = await fetchCompletedRunsData();
          if (data) {
            setCompletedRunsData(data);
          } else {
            setError('No data available');

          }
        }
      } catch (error) {
        alert('Error fetching data:', error);
        setError('Failed to fetch data');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  // Handler for expanding/collapsing run cards
  const handleCardExpand = (cardId) => {
    setExpandedCard(expandedCard === cardId ? null : cardId);
  };

  
  

  return (
    <div className="min-h-screen bg-gray-50 font-sans">
      {/* Header component with navigation */}
      <Header 
        showNewTuneButton={true} 
        activeItem={activeItem}
        onNavItemClick={setActiveItem}
      />
      
      {/* Main content area */}
      <div className="container mx-auto px-6 py-8">
        {/* Conditional rendering based on loading and error states */}
        {isLoading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
          </div>
        ) : error === 'No data available' ? (
          <div className="flex flex-col items-center justify-center h-64">
            <div className="flex items-center justify-center mb-4">
              <FolderPlus size={48} className="text-gray-400" />
            </div>
            <p className="text-sm text-gray-600 mb-2">No fine-tuning runs</p>
            <p className="text-sm text-gray-600 mb-2">Get started by creating a new fine-tuning run</p>
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => navigate('/finetune-form')}>
              New Fine-Tuning
            </button>
          </div>
        ) : error ? (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded-lg">
            {error}
          </div>
        ) : (
          <div className="space-y-6">
            {/* Render appropriate section based on active sidebar item */}
            {activeItem === "Profile" && (
              <ProfileSection 
                profileData={profileData}
                setProfileData={setProfileData}
                error={error}
                setError={setError}
              />
            )}
            {activeItem === "My Datasets" && <DatasetsSection />}
            {/* Render run cards for completed fine-tuning runs */}
            {activeItem === "Tune Runs" && Object.entries(completedRunsData).map(([runId, runData], index) => (
              <RunCard
                key={runId}
                runId={runId}
                runData={runData}
                index={index}
                expandedCard={expandedCard}
                handleCardExpand={handleCardExpand}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                activeEvalTab={activeEvalTab}
                setActiveEvalTab={setActiveEvalTab}
              />
            ))}
          </div>
        )}
      </div>
      
    </div>
  );
}
