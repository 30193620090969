import React, { useState } from 'react';
import { Eye, EyeOff } from 'lucide-react';
import { doc, setDoc } from 'firebase/firestore';
import { db, auth } from '../../firebase/config';

const ProfileSection = ({ profileData, setProfileData, error, setError }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [showTokens, setShowTokens] = useState(false);

  const handleProfileUpdate = async () => {
    try {
      const user = auth.currentUser;
      if (!user) {
        setError('No user logged in');
        return;
      }

      const userDocRef = doc(db, 'users', user.uid);
      await setDoc(userDocRef, {
        huggingFaceToken: profileData.huggingFaceToken,
        modalSecret: profileData.modalSecret,
        modalToken: profileData.modalToken,
        updatedAt: new Date().toISOString()
      }, { merge: true });

      setIsEditing(false);
      // Show success message or notification here if needed
    } catch (error) {
      console.error('Error updating profile:', error);
      setError('Failed to update profile');
    }
  };

  return (
    <div className="bg-white rounded-xl shadow-sm overflow-hidden border border-gray-100 p-6">
      <div className="max-w-2xl mx-auto">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-semibold text-gray-900">Profile Settings</h2>
          {error && (
            <div className="text-red-600 text-sm">{error}</div>
          )}
        </div>
        
        <div className="space-y-6">
          {/* Email Section */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Email Address
            </label>
            <input
              type="email"
              value={profileData.email}
              onChange={(e) => setProfileData({ ...profileData, email: e.target.value })}
              disabled={true} // Email should always be disabled as it comes from auth
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-50"
            />
          </div>

          {/* API Keys Section */}
          <div className="space-y-4">
            <h3 className="text-lg font-medium text-gray-900">API Keys</h3>
            
            {/* HuggingFace Token */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                HuggingFace Token
              </label>
              <div className="relative">
                <input
                  type={showTokens ? "text" : "password"}
                  value={profileData.huggingFaceToken}
                  onChange={(e) => setProfileData({ ...profileData, huggingFaceToken: e.target.value })}
                  disabled={!isEditing}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  placeholder={isEditing ? "Enter your HuggingFace token" : "••••••••"}
                />
              </div>
            </div>

            {/* Modal Tokens */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Modal Secret
              </label>
              <div className="relative">
                <input
                  type={showTokens ? "text" : "password"}
                  value={profileData.modalSecret}
                  onChange={(e) => setProfileData({ ...profileData, modalSecret: e.target.value })}
                  disabled={!isEditing}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  placeholder={isEditing ? "Enter your Modal secret" : "••••••••"}
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Modal Token
              </label>
              <div className="relative">
                <input
                  type={showTokens ? "text" : "password"}
                  value={profileData.modalToken}
                  onChange={(e) => setProfileData({ ...profileData, modalToken: e.target.value })}
                  disabled={!isEditing}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  placeholder={isEditing ? "Enter your Modal token" : "••••••••"}
                />
              </div>
            </div>

            <div className="flex items-center mt-2">
              <button
                onClick={() => setShowTokens(!showTokens)}
                className="text-sm text-blue-600 hover:text-blue-700 flex items-center"
              >
                {showTokens ? (
                  <>
                    <EyeOff className="w-4 h-4 mr-1" />
                    Hide tokens
                  </>
                ) : (
                  <>
                    <Eye className="w-4 h-4 mr-1" />
                    Show tokens
                  </>
                )}
              </button>
            </div>
          </div>

          {/* Action Buttons */}
          <div className="flex justify-end space-x-4 pt-4">
            {isEditing ? (
              <>
                <button
                  onClick={() => setIsEditing(false)}
                  className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
                >
                  Cancel
                </button>
                <button
                  onClick={handleProfileUpdate}
                  className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                >
                  Save Changes
                </button>
              </>
            ) : (
              <button
                onClick={() => setIsEditing(true)}
                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
              >
                Edit Profile
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSection; 