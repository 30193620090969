import React, { createContext, useState, useContext, useEffect } from 'react';
import { auth } from '../firebase/config';
import { onAuthStateChanged } from 'firebase/auth';

export const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log("AuthProvider mounted");
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      console.log("Auth state changed:", {
        user: currentUser ? currentUser.email : 'null',
        timestamp: new Date().toISOString()
      });
      setUser(currentUser);
      setLoading(false);
    });

    return () => {
      console.log("AuthProvider cleanup");
      unsubscribe();
    };
  }, []);

  const value = {
    user,
    loading
  };

  console.log("AuthProvider rendering:", { loading, hasUser: !!user });
  return (
    <AuthContext.Provider value={value}>
      {!loading ? children : <div>Loading auth context...</div>}
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);
